import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import SettingValueString from '@/pages/Experiment/components/Settings/components/SettingValueString';
import SettingValueBoolean from '@/pages/Experiment/components/Settings/components/SettingValueBoolean';
import SettingValueFloat from '@/pages/Experiment/components/Settings/components/SettingValueFloat';
import SettingValueTable from '@/pages/Experiment/components/Settings/components/SettingValueTable';
import { MDASH } from '@/helpers';
import NoDataFound from '@/components/common/NoDataFound';
import styles from './SettingBlock.module.scss';

const cn = classnames.bind(styles);

type TSettingBlock = {
  settingBlock: TInstrumentSettings;
};

const SettingBlock: FC<TSettingBlock> = ({ settingBlock }) => {
  const decodeString = (str: string) =>
    str?.replace(/\\u(\w{4})/gi, (match: string, grp: string) => String.fromCharCode(parseInt(grp, 16)));

  const generateSettingValue = (type: string, value: TSettingOptionVariableValue) => {
    const settingValueTypes: Record<string, ReactNode> = {
      str: <SettingValueString value={value} />,
      range: <SettingValueString value={value} />,
      lane: <SettingValueString value={value} />,
      list: <SettingValueString value={value} />,
      bool: <SettingValueBoolean value={value} />,
      float: <SettingValueFloat value={value} />,
      table: <SettingValueTable value={value} />,
      locations: <SettingValueTable value={value} />,
    };

    return settingValueTypes[type] ?? <NoDataFound textData={MDASH} alignment="left" />;
  };

  return (
    <div className={cn('settings-block')}>
      <div className={cn('settings-block__title')}>{settingBlock.category}</div>
      <div className={cn('settings-block__text-content')}>
        {settingBlock.variables.map((settingRow: TInstrumentSettingsVariable) => (
          <div
            key={settingRow.name}
            className={cn('settings-block__text-content_group', {
              'settings-block__text-content_group_column': settingRow.type === 'table',
            })}
          >
            <div
              className={cn('settings-block__item', {
                'settings-block__item_no-item': !decodeString(settingRow.description),
              })}
            >
              {decodeString(settingRow.description) ?? MDASH}
            </div>
            <div className={cn('settings-block__item', { 'settings-block__item_scroll': settingRow.type === 'table' })}>
              {generateSettingValue(settingRow.type, settingRow.value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingBlock;
