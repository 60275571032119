import { TAppEndpointBuilder } from '@/store/services/app/types';

export const instrumentsEndpoints = (build: TAppEndpointBuilder) => ({
  fetchInstruments: build.query<Array<InstrumentFromServer>, void>({
    query: () => ({
      url: '/instruments',
      method: 'GET',
    }),
  }),
});
