import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { groupBy, isNotEmptyElement, removeDuplicates } from '@/helpers';
import { getChartDataName, getDetailsLists } from '@/helpers/datasetDetails';
import { Action } from '@/helpers/interfaces';

import { appAPI } from '../../services/app';

export type TInitialDatasetsState = {
  isDatasetsView: boolean;
  isPreprocessingView: boolean;
  isEditMode: boolean;
  uniqueChannelNameList: string[];
  includedChannels: string[];
  includedTimePoints: string[];
  filterAssayId: Nullable<string>;
  channelDetailsLists: Record<string, TDatasetDetails[]>;
  datasetDetailsLists: Record<string, TDatasetDetails[]>;
  laneDetailsLists: Record<string, TLaneDetails[]>;
};

const initialState: TInitialDatasetsState = {
  isDatasetsView: true,
  isPreprocessingView: false,
  isEditMode: false,
  uniqueChannelNameList: [],
  includedChannels: [],
  includedTimePoints: [],
  filterAssayId: null,
  channelDetailsLists: {},
  datasetDetailsLists: {},
  laneDetailsLists: {},
};

const toggleValueInArray = (valueList: string[], value: string) => {
  const valueSet = new Set(valueList);
  if (valueSet.has(value)) {
    valueSet.delete(value);
  } else {
    valueSet.add(value);
  }
  return [...valueSet];
};

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    toggleLaneDetails: (state, action) => {
      const { experimentId, laneId, isOpen } = action.payload;
      const laneDetails = state.laneDetailsLists[experimentId]?.find(
        (laneDetailsItem) => laneDetailsItem.id === laneId
      );
      if (laneDetails) {
        laneDetails.isOpen = typeof isOpen === 'undefined' ? !laneDetails.isOpen : isOpen;
      }
    },

    changeChannelAssay: (state, action) => {
      const { experimentId, channelId, assay, scanId, laneId } = action.payload;

      state.channelDetailsLists[experimentId]?.forEach((channel, index) => {
        if (channel.scanId !== scanId || channel.laneId !== laneId || channel.channelId !== channelId) {
          return;
        }
        state.channelDetailsLists[experimentId][index] = {
          ...channel,
          assay,
        };
      });

      const channelDetailsLists = state.channelDetailsLists[experimentId];

      const groupedChannelsByDataset = groupBy(channelDetailsLists, (el) => el.dataset.id) as Record<
        string,
        TDatasetDetails[]
      >;

      const groupedAssaysByDataset = Object.entries(groupedChannelsByDataset).reduce(
        (acc: Record<string, TAssay[]>, [key, value]) => {
          acc[key] = removeDuplicates(value.map((el) => el.assay).filter(isNotEmptyElement), 'id');
          return acc;
        },
        {}
      );

      state.datasetDetailsLists[experimentId]?.forEach((dataset, index) => {
        if (dataset.scanId !== scanId || dataset.laneId !== laneId) {
          return;
        }

        const assayList = groupedAssaysByDataset[dataset.dataset.id];

        state.datasetDetailsLists[experimentId][index] = {
          ...dataset,
          assay,
          assayList,
        };
      });
    },

    changeDatasetFriendlyName: (state, action) => {
      const { experimentId, friendlyName, scanId, laneId } = action.payload;

      state.channelDetailsLists[experimentId] = state.channelDetailsLists[experimentId].map((chartData) => {
        if (chartData.scanId === scanId && chartData.laneId === laneId) {
          return {
            ...chartData,
            dataset: { ...chartData.dataset, friendlyName },
            friendlyName: getChartDataName(friendlyName, chartData.channelName ?? ''),
          };
        }
        return chartData;
      });

      state.datasetDetailsLists[experimentId]?.forEach((dataset, index) => {
        if (dataset.scanId !== scanId || dataset.laneId !== laneId) {
          return;
        }
        state.datasetDetailsLists[experimentId][index] = {
          ...dataset,
          friendlyName,
        };
      });
    },

    changeSampleFriendlyName: (state, action) => {
      const { experimentId, friendlyName, laneId } = action.payload;

      state.laneDetailsLists[experimentId]?.forEach((lane, index) => {
        if (lane.id !== laneId) {
          return;
        }
        state.laneDetailsLists[experimentId][index] = {
          ...lane,
          sampleFriendlyName: friendlyName,
        };
      });
    },
    changeChannelReagentList: (state, action) => {
      const { experimentId, scanId, laneId, channelId, reagentList, idReagentToDelete } = action.payload;
      const channelDetails = state.channelDetailsLists[experimentId]?.find(
        (channelDetailsItem) =>
          channelDetailsItem.scanId === scanId &&
          channelDetailsItem.laneId === laneId &&
          channelDetailsItem.channelId === channelId
      );
      if (channelDetails) {
        if (reagentList) {
          channelDetails.reagents = reagentList;
        }
        if (idReagentToDelete) {
          channelDetails.reagents =
            channelDetails.reagents?.filter((reagent) => reagent.id !== idReagentToDelete) ?? [];
        }
      }
    },
    toggleDatasetDetails: (state, action: PayloadAction<string>) => {
      const datasetId = action.payload;
      Object.values(state.datasetDetailsLists).forEach((list) => {
        list.forEach((dataset, index) => {
          if (datasetId !== dataset.id) {
            return;
          }
          list[index] = {
            ...dataset,
            checked: !dataset.checked,
          };
        });
      });
    },
    toggleChannelDetails: (state, action: PayloadAction<string>) => {
      const channelId = action.payload;
      Object.values(state.channelDetailsLists).forEach((list) => {
        list.forEach((channel, index) => {
          if (channelId !== channel.id) {
            return;
          }
          list[index] = {
            ...channel,
            checked: !channel.checked,
          };
        });
      });
    },
    setEditMode: (state, action: PayloadAction<{ isEditMode: boolean }>) => {
      const { isEditMode } = action.payload;
      state.isEditMode = isEditMode;
    },
    setChannelDetailsChecked: (
      state,
      action: PayloadAction<{ detailedChannelIdList: string[]; isChecked: boolean }>
    ) => {
      const { detailedChannelIdList, isChecked } = action.payload;
      Object.values(state.channelDetailsLists).forEach((list) => {
        list.forEach((channel, index) => {
          if (!detailedChannelIdList.includes(channel.id)) {
            return;
          }
          list[index] = {
            ...channel,
            checked: isChecked,
          };
        });
      });
    },
    setAllChannelDetailsUnchecked: (state) => {
      Object.values(state.channelDetailsLists).forEach((list) => {
        list.forEach((channel, index) => {
          list[index] = {
            ...channel,
            checked: false,
          };
        });
      });
    },
    toggleIsDatasetsView: (state) => {
      state.isDatasetsView = !state.isDatasetsView;
    },
    toggleIsPreprocessingView: (state, action: PayloadAction<boolean>) => {
      state.isPreprocessingView = action.payload;
    },
    setIsDatasetsView: (state, action) => {
      state.isDatasetsView = action.payload;
    },
    setIncludedChannels: (state, action) => {
      state.uniqueChannelNameList = action.payload;
      state.includedChannels = action.payload;
    },
    toggleIncludedChannel: (state, action) => {
      let newIncludedChannelNameList = [...state.includedChannels];
      state.uniqueChannelNameList.forEach((channelName) => {
        if (channelName.includes(action.payload)) {
          newIncludedChannelNameList = toggleValueInArray(newIncludedChannelNameList, channelName);
        }
      });
      state.includedChannels = newIncludedChannelNameList;
    },
    setIncludedTimePoints: (state, action) => {
      state.includedTimePoints = action.payload;
    },
    toggleIncludedTimePoint: (state, action) => {
      state.includedTimePoints = toggleValueInArray(state.includedTimePoints, action.payload);
    },
    setFilterAssayId: (state, action) => {
      state.filterAssayId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(appAPI.endpoints.fetchExperimentScans.matchFulfilled, (state, { payload, meta }) => {
        const experimentId = meta.arg.originalArgs;
        if (!experimentId) {
          return;
        }
        const { channelDetailsList, laneDetailsList, datasetDetailsList } = getDetailsLists(payload);
        state.channelDetailsLists[experimentId] = channelDetailsList;
        state.laneDetailsLists[experimentId] = laneDetailsList;
        state.datasetDetailsLists[experimentId] = datasetDetailsList;
      })
      .addMatcher(appAPI.endpoints.updateAssay.matchPending, (state, action: Action<string, unknown, unknown>) => {
        action.payload = action.meta.arg.originalArgs;
        datasetsSlice.caseReducers.changeChannelAssay(state, action);
      })
      .addMatcher(
        appAPI.endpoints.updateExperimentSampleFriendlyName.matchPending,
        (state, action: Action<string, unknown, unknown>) => {
          action.payload = action.meta.arg.originalArgs;
          datasetsSlice.caseReducers.changeSampleFriendlyName(state, action);
        }
      )
      .addMatcher(
        appAPI.endpoints.updateReagentList.matchPending,
        (state, action: Action<string, unknown, unknown>) => {
          action.payload = action.meta.arg.originalArgs;
          datasetsSlice.caseReducers.changeChannelReagentList(state, action);
        }
      )
      .addMatcher(
        appAPI.endpoints.updateExperimentDatasetFriendlyName.matchPending,
        (state, action: Action<string, unknown, unknown>) => {
          action.payload = action.meta.arg.originalArgs;
          datasetsSlice.caseReducers.changeDatasetFriendlyName(state, action);
        }
      )
      .addMatcher(appAPI.endpoints.deleteReagent.matchPending, (state, action: Action<string, unknown, unknown>) => {
        action.payload = action.meta.arg.originalArgs;
        datasetsSlice.caseReducers.changeChannelReagentList(state, action);
      });
  },
});

export default datasetsSlice;
