import { PlacesType } from 'react-tooltip';

export const TOOLTIP_ID = 'app-hover-tooltip';
export const TOOLTIP_CLICK_ID = 'app-click-tooltip';

export const ETooltipTypeMap = {
  default: TOOLTIP_ID,
  click: TOOLTIP_CLICK_ID,
};

export const addTooltip = (tooltip?: string, place?: PlacesType, tooltipType = ETooltipTypeMap.default) =>
  tooltip
    ? {
        'data-tooltip-id': tooltipType,
        'data-tooltip-html': tooltip,
        'data-tooltip-place': place,
      }
    : {};
