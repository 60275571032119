import { Reagent } from '@/graphql/API';
import { TReturnTransformedTypes } from './types';

const checkIsReagent = (reagent: unknown): reagent is Reagent => {
  const data = reagent as Reagent;
  return 'type' in data && data.type === 'reagent';
};

const transformWaveLength = (wavelengthFromServer?: number | string | number[] | string[] | null) => {
  if (Array.isArray(wavelengthFromServer) && wavelengthFromServer.length > 0) {
    return +wavelengthFromServer[0];
  }
  if (typeof wavelengthFromServer === 'string' || typeof wavelengthFromServer === 'number') {
    return +wavelengthFromServer;
  }
  return null;
};

const transformReagent = (data: TGeneralAnnotationFields): Reagent => {
  const resultData: Reagent = {
    __typename: 'Reagent',
    type: 'Reagent',
    id: data.id,
    name: data.name,
  };

  if (checkIsReagent(data)) {
    resultData.annotations = data.annotations ?? null;
    resultData.detectionWavelength = transformWaveLength(data.detectionWavelength);
    resultData.excitationWavelength = transformWaveLength(data.excitationWavelength);
    resultData.marker = data.marker ?? null;
    resultData.phenotype = data.phenotype ?? null;
  }
  return resultData;
};

export const transformMap: Record<string, (reagent: TGeneralAnnotationFields) => TReturnTransformedTypes> = {
  reagent: transformReagent,
};
