import { FC, RefObject, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { navigatorActions, navigatorSelectors, TRenderOption } from '@/store/slices/navigator';
import { CSSProperty } from '@/helpers/interfaces';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import ColorPicker from '@/components/common/ColorPicker';
import Range from '@/components/common/Range';
import { Popover as TinyPopover } from 'react-tiny-popover';
import icons from '@/components/common/icons';
import { useParamsExperimentId } from '@/hooks';
import { lutImageURIList } from '@/hooks/useWebgl/lut';
import styles from './Channels.module.scss';

const cn = classnames.bind(styles);

type ChannelPopoverContent = {
  label: string;
  isActive: boolean;
  range: [number, number];
  color: string;
  onIsActiveChange: () => void;
  onRangeChange: (_newRange: [number, number]) => void;
  onColorChange: (_color: string) => void;
  onLutChange: (type: string) => void;
  isLutSelected: boolean;
  lutType: string;
};

const ChannelPopoverContent: FC<ChannelPopoverContent> = ({
  label,
  isActive,
  range,
  color,
  onIsActiveChange,
  onRangeChange,
  onColorChange,
  onLutChange,
  lutType,
  isLutSelected,
}) => (
  <div className={cn('channel__popover', { channel_disabled: !isActive })}>
    <div className={cn('channel__label-wrap')}>
      <div className={cn('channel__label')}>{label}</div>
      <button className={cn('channel__visibility-button')} onClick={onIsActiveChange}>
        {isActive ? <icons.VisibilityOnIcon /> : <icons.VisibilityOffIcon />}
      </button>
    </div>

    <div className={cn('channel__separator')} />

    <div className={cn('channel__range')}>
      <Range
        type="range"
        value={range}
        min={0}
        isControlInput
        max={255}
        fixedValue={0}
        step={1}
        onChange={(newRange) => onRangeChange(newRange as [number, number])}
        className={cn('channel__range-slider')}
        inputClassName={cn('channel__range-input')}
      />
    </div>

    <div className={cn('channel__separator')} />

    <div className={cn('channel__color-picker')}>
      <ColorPicker
        value={color}
        onChange={onColorChange}
        disabled={false}
        onLutChange={onLutChange}
        isLutSelected={isLutSelected}
        lutType={lutType}
      />
    </div>
  </div>
);

const Channel: FC<TRenderOption & { id: string; rootRef: RefObject<HTMLDivElement> }> = ({
  id,
  label,
  isActive,
  color,
  range,
  rootRef,
  isUseLut,
  lutType,
}) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverOpen = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const popoverProps = {
    label,
    isActive,
    range,
    color,
    lutType,
    onIsActiveChange: () => {
      appDispatch(
        navigatorActions.setRenderOptions({
          experimentId,
          channelId: id,
          options: {
            isActive: !isActive,
          },
        })
      );
    },
    onRangeChange: (newRange: [number, number]) =>
      appDispatch(
        navigatorActions.setRenderOptions({
          experimentId,
          channelId: id,
          options: {
            range: newRange,
          },
        })
      ),
    onColorChange: (newColor: string) =>
      appDispatch(
        navigatorActions.setRenderOptions({
          experimentId,
          channelId: id,
          options: {
            color: newColor,
            isUseLut: false,
          },
        })
      ),
    onLutChange: (type: string) => {
      appDispatch(
        navigatorActions.setRenderOptions({
          experimentId,
          channelId: id,

          options: {
            isUseLut: true,
            lutType: type,
          },
        })
      );
    },
    isLutSelected: isUseLut,
  };

  const handleVisibilityToggle = () => {
    appDispatch(
      navigatorActions.setRenderOptions({
        experimentId,
        channelId: id,
        options: {
          isActive: !isActive,
        },
      })
    );
  };

  return (
    <div className={cn('channel', { channel_disabled: !isActive })}>
      <button className={cn('channel__content')} onClick={handlePopoverOpen}>
        {!isUseLut && (
          <div className={cn('channel__color-preview')} style={{ '--preview-color': color } as CSSProperty} />
        )}
        {isUseLut && (
          <div className={cn('channel__lut')}>
            <img src={lutImageURIList[lutType]} alt="" className={cn('channel__lut-image')} />
          </div>
        )}
        <div className={cn('channel__label')}>{label}</div>
      </button>

      <button
        className={cn('channel__action-button', 'channel__action-button_visibility')}
        onClick={handleVisibilityToggle}
      >
        {isActive ? <icons.VisibilityOnIcon /> : <icons.VisibilityOffIcon />}
      </button>

      <TinyPopover
        onClickOutside={handlePopoverClose}
        isOpen={isPopoverOpen}
        content={<ChannelPopoverContent {...popoverProps} />}
        parentElement={rootRef.current ?? undefined}
        positions={['left']}
        clickOutsideCapture
      >
        <button
          onClick={handlePopoverOpen}
          className={cn('channel__action-button', 'channel__action-button_options')}
          aria-label="menu"
        >
          <icons.ThreeDotIcon />
        </button>
      </TinyPopover>
    </div>
  );
};

const ChannelList: FC = () => {
  const renderOptions = useSelector(navigatorSelectors.selectRenderOptions);
  const rootRef = useRef<HTMLDivElement>(null);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);

  const channelList = useSelector(navigatorSelectors.selectChannelList(currentScanId, currentLaneId));
  const filteredRenderOptions = channelList.reduce((acc, channelId) => {
    acc[channelId] = renderOptions[channelId];

    return acc;
  }, {} as Record<string, TRenderOption>);
  const channelListOptions = useMemo(() => Object.entries(filteredRenderOptions), [filteredRenderOptions]);

  return (
    <div ref={rootRef} className={cn('channel-list')}>
      <div className={cn('channel-list__title')}>Channels</div>
      <div className={cn('channel-list__list')}>
        {channelListOptions.map(([channelId, props], i) => (
          <Channel key={i} id={channelId} {...props} rootRef={rootRef} />
        ))}
      </div>
    </div>
  );
};

export default ChannelList;
