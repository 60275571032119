const DEFAULT_TITLE_LAYOUT = {
  family: 'SFPro, Arial, sans-serif',
  size: 13,
  color: '#989898',
};

const DEFAULT_MARGINS = {
  l: 50,
  r: 20,
  t: 20,
  b: 70,
  pad: 0,
};

export const prepareLayout = (graph: IPlotlyHTMLDivElement, xAxisLabel = '', yAxisLabel = ''): TPlotLayout => {
  const layout = graph.layout ? structuredClone(graph.layout) : ({} as TPlotLayout);

  // setup x axis view
  layout.xaxis = {
    ...layout.xaxis,
    title: {
      ...DEFAULT_TITLE_LAYOUT,
      text: xAxisLabel,
      font: {
        size: 22,
      },
    },
    color: '#000000',
    tickfont: {
      size: 20,
    },
  };

  // setup y axis view
  layout.yaxis = {
    ...layout.yaxis,
    title: {
      ...DEFAULT_TITLE_LAYOUT,
      text: yAxisLabel,
      font: {
        size: 22,
      },
    },
    color: '#000000',
    tickfont: {
      size: 20,
    },
  };

  layout.paper_bgcolor = '#ffff';
  layout.margin = {
    ...DEFAULT_MARGINS,
    l: yAxisLabel ? 100 : 80,
    b: xAxisLabel ? 100 : 80,
  };
  if (layout.legend) {
    layout.legend.font = {
      size: 20,
    };
  }

  return layout;
};

export const prepareMatrixLayout = (graph: IPlotlyHTMLDivElement, xAxisLabel: string, yAxisLabel: string) => {
  const layout = graph.layout ? structuredClone(graph.layout) : ({} as TPlotLayout);

  // setup x axis view
  layout.xaxis = {
    ...layout.xaxis,
    title: {
      ...DEFAULT_TITLE_LAYOUT,
      text: graph.layout ? xAxisLabel : '',
      font: {
        size: 14,
      },
    },
    color: '#000000',
    tickfont: {
      size: 12,
    },
  };

  // setup y axis view
  layout.yaxis = {
    ...layout.yaxis,
    title: {
      ...DEFAULT_TITLE_LAYOUT,
      text: graph.layout ? yAxisLabel : '',
      font: {
        size: 14,
      },
    },
    color: '#000000',
    tickfont: {
      size: 12,
    },
  };

  layout.paper_bgcolor = '#ffff';
  layout.margin = {
    ...DEFAULT_MARGINS,
    t: 70,
    l: graph.layout && yAxisLabel ? 60 : 20,
    b: graph.layout && xAxisLabel ? 65 : 20,
  };
  if (layout.legend) {
    layout.legend.font = {
      size: 12,
    };
  }

  const marginMultipliers = {
    t: graph.layout ? graph.layout.margin.t / layout.margin.t : 1,
    l: graph.layout ? graph.layout.margin.l / layout.margin.l : 1,
    b: graph.layout ? graph.layout.margin.b / layout.margin.b : 1,
    r: graph.layout ? graph.layout.margin.r / layout.margin.r : 1,
  };
  return { layout, marginMultipliers };
};
