import { Fragment, useCallback, useId, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isDatasetDetailsNeedPreprocessing } from '@/helpers/preprocessing';
import { lexSort } from '@/helpers/arrays';

import useSorting from '@/hooks/useSorting';

import { datasetsSelectors, datasetsActions } from '@/store/slices/datasets';
import { preprocessingSelectors } from '@/store/slices/preprocessing';

import { ECheckedState } from '@/components/common/CheckboxInput/types';
import CheckboxInput from '@/components/common/CheckboxInput';
import NoDataFound from '@/components/common/NoDataFound';
import SortedFieldHeading from '@/components/common/SortedFieldHeading';

import ChannelDetailsRow from './ChannelDetailsRow';

import styles from './ChannelDetailsTable.module.scss';

const cn = classnames.bind(styles);

type TChannelDetailsTableProps = {
  laneId: string;
  isMinimalView: boolean;
};

const ChannelDetailsTable = ({ laneId, isMinimalView }: TChannelDetailsTableProps) => {
  const { experimentId = '' } = useParams();
  const dispatch = useDispatch();
  const uid = useId();
  const channelDetailsList = useSelector(datasetsSelectors.selectChannelDetailsList(experimentId, laneId));
  const includedChannels = useSelector(datasetsSelectors.selectIncludedChannels);
  const includedTimePoints = useSelector(datasetsSelectors.selectIncludedTimePoints);
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const preprocessingAssayId = useSelector(preprocessingSelectors.selectPreprocessingAssayId);

  const filteredChannelDetailsList = useMemo(() => {
    if (!isPreprocessingView) {
      const defaultFilter = channelDetailsList.filter(
        (channelDetails) =>
          channelDetails.channelName &&
          includedChannels.includes(channelDetails.channelName) &&
          includedTimePoints.includes(channelDetails.timePoint)
      );
      return defaultFilter;
    }

    return channelDetailsList.filter((channelDetails) =>
      isDatasetDetailsNeedPreprocessing(channelDetails, preprocessingAssayId)
    );
  }, [preprocessingAssayId, channelDetailsList, laneId, includedChannels, includedTimePoints, isPreprocessingView]);

  const sortingMethods = {
    name: (a: TDatasetDetails, b: TDatasetDetails) => lexSort(a.friendlyName, b.friendlyName),
    time: (a: TDatasetDetails, b: TDatasetDetails) => lexSort(a.timePoint, b.timePoint),
    channel: (a: TDatasetDetails, b: TDatasetDetails) => lexSort(a.channelName ?? '', b.channelName ?? ''),
    assay: (a: TDatasetDetails, b: TDatasetDetails) => {
      if (!a.assay) {
        return -1;
      }

      if (!b.assay) {
        return 1;
      }

      return lexSort(a.assay.name, b.assay.name);
    },
    marker: (a: TDatasetDetails, b: TDatasetDetails) => {
      if (!a.reagents?.length) {
        return -1;
      }

      if (!b.reagents?.length) {
        return 1;
      }

      return lexSort(a.reagents[0].name, b.reagents[0].name);
    },
    default: (a: TDatasetDetails, b: TDatasetDetails) => lexSort(a.id, b.id),
  };

  const { setSortingSettings, sortedData, sortingField, sortingOrder } = useSorting(
    filteredChannelDetailsList,
    sortingMethods
  );

  const isAllSelected = useMemo(
    () =>
      filteredChannelDetailsList.length > 0 &&
      filteredChannelDetailsList.every((channelDetails) => channelDetails.checked),
    [filteredChannelDetailsList]
  );

  const isNothingSelected = useMemo(
    () => !filteredChannelDetailsList.some((channelDetails) => channelDetails.checked),
    [filteredChannelDetailsList]
  );

  const checkedState = useMemo(() => {
    if (isAllSelected) {
      return true;
    }
    if (isNothingSelected) {
      return false;
    }
    return ECheckedState.indeterminate;
  }, [isAllSelected, isNothingSelected]);

  const handleSelectAllChange = useCallback(() => {
    const detailedChannelIdList = filteredChannelDetailsList.map((channelDetails) => channelDetails.id);
    dispatch(datasetsActions.setChannelDetailsChecked({ detailedChannelIdList, isChecked: isNothingSelected }));
  }, [filteredChannelDetailsList, isNothingSelected]);

  const handleSortingFactory = (field: string) => () => {
    setSortingSettings(field);
  };

  return (
    <div className={cn('table-wrapper', { 'table-wrapper_minimal': isMinimalView })}>
      <div className={cn('table-scroll')}>
        <table className={cn('table', 'channel-details-table')}>
          <thead className={cn('table__head')}>
            <tr className={cn('table__row')}>
              {!isPreprocessingView && (
                <th className={cn('table__head-item', 'table__head-item_fit-content')} aria-label="checkbox">
                  <CheckboxInput
                    checked={checkedState}
                    onChange={handleSelectAllChange}
                    className={cn('table-item__checkbox', 'table-item__checkbox_header')}
                    theme="light"
                  />
                </th>
              )}
              <th className={cn('table__head-item')}>
                <SortedFieldHeading
                  id={`${uid}-name`}
                  fieldName="name"
                  sortingField={sortingField}
                  sortingOrder={sortingOrder}
                  onChangeSorting={handleSortingFactory('name')}
                >
                  Dataset name
                </SortedFieldHeading>
              </th>

              <th
                className={cn(
                  'table__head-item',
                  'table__head-item_center',
                  'channel-details-table__head-item',
                  'channel-details-table__head-item_time'
                )}
              >
                <SortedFieldHeading
                  id={`${uid}-time`}
                  fieldName="time"
                  sortingField={sortingField}
                  sortingOrder={sortingOrder}
                  onChangeSorting={handleSortingFactory('time')}
                >
                  Time point
                </SortedFieldHeading>
              </th>
              <th
                className={cn(
                  'table__head-item',
                  'table__head-item_center',
                  'table__head-item_fit-content',
                  'channel-details-table__head-item',
                  'channel-details-table__head-item_channel'
                )}
              >
                <SortedFieldHeading
                  id={`${uid}-channel`}
                  fieldName="channel"
                  sortingField={sortingField}
                  sortingOrder={sortingOrder}
                  onChangeSorting={handleSortingFactory('channel')}
                >
                  Channel
                </SortedFieldHeading>
              </th>
              {!isMinimalView && (
                <>
                  <th
                    className={cn(
                      'table__head-item',
                      'table__head-item_fit-content',
                      'channel-details-table__head-item',
                      'channel-details-table__head-item_assay'
                    )}
                  >
                    <SortedFieldHeading
                      id={`${uid}-assay`}
                      fieldName="assay"
                      sortingField={sortingField}
                      sortingOrder={sortingOrder}
                      onChangeSorting={handleSortingFactory('assay')}
                    >
                      <span className={cn('label-group')}>
                        <span className={cn('label-group__item')}>Assay</span>
                        <span className={cn('label-group__item')}>
                          Preprocessing <br /> Required
                        </span>
                      </span>
                    </SortedFieldHeading>
                  </th>
                  <th
                    className={cn(
                      'table__head-item',
                      'table__head-item_center',
                      'table__head-item_fit-content',
                      'channel-details-table__head-item',
                      'channel-details-table__head-item_marker'
                    )}
                  >
                    Marker
                  </th>
                  <th
                    className={cn(
                      'table__head-item',
                      'table__head-item_center',
                      'table__head-item_fit-content',
                      'channel-details-table__head-item',
                      'channel-details-table__head-item_reagent'
                    )}
                  >
                    <SortedFieldHeading
                      id={`${uid}-marker`}
                      fieldName="marker"
                      sortingField={sortingField}
                      sortingOrder={sortingOrder}
                      onChangeSorting={handleSortingFactory('marker')}
                    >
                      Reagent
                    </SortedFieldHeading>
                  </th>
                  <td className={cn('table__head-item', 'table__head-item_fit-content')} aria-label="empty" />
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredChannelDetailsList.length === 0 && (
              <tr>
                <td colSpan={!isPreprocessingView ? 9 : 8} aria-label="empty">
                  <NoDataFound
                    className={cn('table__no-data')}
                    size="normal"
                    alignment="center"
                    textData={channelDetailsList.length ? 'No data selected' : 'No data found'}
                  />
                </td>
              </tr>
            )}

            {sortedData.map((channelDetails) => (
              <Fragment key={`${channelDetails.id}`}>
                <ChannelDetailsRow
                  key={channelDetails.id}
                  channelDetails={channelDetails}
                  isMinimalView={isMinimalView}
                />
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChannelDetailsTable;
