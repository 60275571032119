import { TAnnotationsEndpointBuilder } from '@/store/services/app/types';
import { transformAnnotationResponse, transformAnnotationsResponse } from '../dataProvider/annotation';
import { TAnnotationType } from './types';
import { TReturnTransformedTypes } from '../dataProvider/types';

export const annotationEndpoints = (build: TAnnotationsEndpointBuilder) => ({
  fetchAnnotations: build.query<TReturnTransformedTypes[], { type: TAnnotationType; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
      },
    }),
    transformResponse: transformAnnotationsResponse,
  }),
  fetchBeadById: build.query<TReturnTransformedTypes, string>({
    query: (id = '') => ({
      url: `/annotations/${id}`,
    }),
    transformResponse: transformAnnotationResponse,
  }),
});
