import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getKeyName } from '@/helpers';
import { getCorrectNameOfAxes } from '@/helpers/preprocessing';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { useDebounce } from '../../useDebounce';

type TRangeData = {
  name: string;
  datasetId: string;
  xAxisFromMap: string;
  yAxisFromMap: string;
};
type TRangesDataMap = Record<string, TRangeData>;

// TODO: MATRIX_SINGLE_CHART this plot range name need to use if plot was not changed, and need ignore this generated name, because it is not valid
const useMatrixRanges = (chartDataList: TDatasetDetails[]) => {
  const contextChartId = usePlotChartIdContext();
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(contextChartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(contextChartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(chartSettingsSelectors.selectCurrentScalesTypeForAxes());
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(contextChartId));
  const kernelBandwidthCoefficient = useSelector(
    histogramSettingsSelectors.selectKernelBandwidthCoefficient(contextChartId)
  );
  const histogramGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(contextChartId)
  );
  const { optionMapping } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled)
  );
  const debouncedCurrentChartType = useDebounce(currentChartType);
  const [rangesDataMap, setRangesDataMap] = useState<TRangesDataMap>({});

  const optionMappingRef = useRef<Record<string, Record<string, string>>>({});

  useEffect(() => {
    optionMappingRef.current = optionMapping;
  }, [optionMapping]);

  const handleAxisChange = useCallback(
    (xAxis: string, yAxis: string, chartId: string) => {
      if (!xAxis && !yAxis) {
        return;
      }

      const chartData = chartDataList.find(({ id }) => id === chartId);

      if (!chartData) {
        return;
      }

      const { xAxisFromMap, yAxisFromMap } = getCorrectNameOfAxes(
        optionMappingRef.current,
        chartData?.dataset?.name ?? '',
        xAxis,
        yAxis
      );

      const entityType = isObjectEntityEnabled ? 'object' : 'cage';
      const axisName = getKeyName(
        objectType,
        entityType,
        xAxisFromMap,
        yAxisFromMap,
        xAxisScaleType,
        yAxisScaleType,
        debouncedCurrentChartType,
        histogramGroupType,
        `coefficient_${kernelBandwidthCoefficient}`
      );
      const updatedRangeData: TRangeData = {
        name: axisName,
        datasetId: chartId,
        xAxisFromMap,
        yAxisFromMap,
      };
      setRangesDataMap((prev) => ({
        ...prev,
        [chartId]: updatedRangeData,
      }));
    },
    [
      isObjectEntityEnabled,
      objectType,
      chartDataList,
      xAxisScaleType,
      yAxisScaleType,
      debouncedCurrentChartType,
      histogramGroupType,
      kernelBandwidthCoefficient,
    ]
  );

  useEffect(() => {
    const updatedRangesDataMap = { ...rangesDataMap };

    if (!Object.values(updatedRangesDataMap)?.length) return;

    const entityType = isObjectEntityEnabled ? 'object' : 'cage';
    Object.values(updatedRangesDataMap).forEach((rangeData) => {
      const { xAxisFromMap, yAxisFromMap } = rangeData;
      rangeData.name = getKeyName(
        objectType,
        entityType,
        xAxisFromMap,
        yAxisFromMap,
        xAxisScaleType,
        yAxisScaleType,
        debouncedCurrentChartType,
        histogramGroupType,
        `coefficient_${kernelBandwidthCoefficient}`
      );
    });
    setRangesDataMap(updatedRangesDataMap);
  }, [
    isObjectEntityEnabled,
    objectType,
    xAxisScaleType,
    yAxisScaleType,
    debouncedCurrentChartType,
    histogramGroupType,
    kernelBandwidthCoefficient,
  ]);

  return useMemo(
    () => ({
      handleAxisChange,
      rangesDataMap,
    }),
    [handleAxisChange, rangesDataMap]
  );
};
export default useMatrixRanges;
