import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { scatterplotsActions, EAxesGroupName, TAxes, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { experimentSelectors } from '@/store/slices/experiment';
import { EChartType } from '@/types/charts';
import { findLaneInScanList } from '@/helpers/scans';
import { getAssayByLane, isLaneProcessed } from '@/helpers/preprocessing';
import { AXES_DEFAULT_PRESET } from '@/helpers';
import { chartDataSelectors } from '@/store/slices/chartData';

import { getPlotAxesOptionList } from '../../helpers';
import { getGeneralAxes, getPreprocessedXAxis } from './helpers';

export function useMatrixSettingsPreset(chartDataList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const [plotType, setChartType] = useState(EChartType.dotDensity);
  const [isReady, setIsReady] = useState(false);

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const cageLevelAxesOptionListByLanes = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionListByLanes);
  const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
    string,
    TAxes
  >;
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
  const currentLane = findLaneInScanList(scanList, currentChartData?.scanId ?? '', currentChartData?.laneId ?? '');

  useEffect(() => {
    chartDataList.forEach((chartData) => {
      const hasGeneralAxes = generalAxes?.[chartData.id]?.isObjectEntityEnabled === isObjectEntityEnabled;

      if (!Object.keys(cageLevelAxesOptionListByLanes).length) return;

      const assay = getAssayByLane(currentLane, currentChartData?.channelName ?? '');

      if (!isObjectEntityEnabled && !!currentLane && isLaneProcessed(currentLane, assay)) {
        setChartType(EChartType.lineHistogram);
      }

      if (hasGeneralAxes && cageLevelAxesOptionListByLanes[chartData.name]?.length) {
        appDispatch(scatterplotsActions.setCurrentAxesGroupName(EAxesGroupName.general));
        setIsReady(true);
      } else {
        const plotAxesOptionList = getPlotAxesOptionList(
          chartData,
          isObjectEntityEnabled,
          cageLevelAxesOptionListByLanes
        );

        let newAxes: TAxes = {};

        if (chartData.channelId) {
          const preprocessedXAxis = getPreprocessedXAxis(
            scanList,
            chartData,
            plotAxesOptionList,
            chartData?.channelName ?? '',
            chartData.marker?.name ?? ''
          );

          if (!isObjectEntityEnabled && preprocessedXAxis) {
            newAxes.x = preprocessedXAxis;
          } else {
            newAxes.x =
              isObjectEntityEnabled && chartData.channelName === 'White'
                ? AXES_DEFAULT_PRESET.xAxis
                : `${chartData.channelId}_mean`;
          }

          const yAxisForObjectEntity = chartData.channelName === 'White' ? AXES_DEFAULT_PRESET.yAxis : 'object_area_px';
          newAxes.y = isObjectEntityEnabled ? yAxisForObjectEntity : `${chartData.channelId}_bg_mean`;
        } else {
          newAxes = getGeneralAxes(isObjectEntityEnabled, plotAxesOptionList);
        }

        if (newAxes.x || newAxes.y) {
          appDispatch(
            scatterplotsActions.setAxes({
              axesGroupName: EAxesGroupName.general,
              chartDataId: chartData.id,
              newAxes: { ...newAxes, isObjectEntityEnabled },
            })
          );

          setIsReady(true);
        }
      }
    });
  }, [chartDataList, isObjectEntityEnabled, cageLevelAxesOptionListByLanes]);

  return useMemo(() => ({ isReady, plotType }), [isReady, plotType]);
}
