import { SVGProps } from 'react';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2 0h5.273a3 3 0 0 1 2.208.969l3.726 4.05A3 3 0 0 1 14 7.048V15a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
        fill="#5F5F5F"
      />
      <path stroke="#2B2B2B" strokeWidth="1.5" strokeLinecap="round" d="M7.289 2.5v4M11.289 6.5h-4" />
    </g>
  </svg>
);

export default SettingsIcon;
