import { CagingSettingsItem, Lane, LaneCagingSettings } from '@/graphql/API';

import { arrToMapByKeys, isKeyOf, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import {
  TExperimentRunDesignState,
  TRunDesignComponent,
  TRunDesignLane,
} from '@/store/slices/experimentRunDesign/types';
import {
  DEFAULT_CELL_TYPE,
  DEFAULT_GLOBAL_SETTINGS,
  DEFAULT_MAGNIFICATION,
} from '@/pages/experiment-run-design/CagingSettings/constants';

export const getAvailableId = (laneList: TRunDesignLane[], id?: string) => {
  if (id && LANE_LETTER_NAME_LIST.includes(id) && !laneList.find((lane) => lane.id === id)) {
    return id;
  }
  return LANE_LETTER_NAME_LIST.find((laneId) => !laneList.find((lane) => lane.id === laneId));
};

export const removeFromLanes = (lanes: TRunDesignLane[], laneId: string) => {
  const laneIndex = lanes.findIndex((lane) => lane.id === laneId);
  if (laneIndex >= 0) {
    lanes.splice(laneIndex, 1);
  }
};

export const removeLaneFromComponents = (components: TRunDesignComponent[], laneId: string) => {
  components.forEach((component) => {
    const laneIndex = component.performedOnLanes.findIndex((laneReagents) => laneReagents.laneId === laneId);
    if (laneIndex >= 0) {
      component.performedOnLanes.splice(laneIndex, 1);
    }
  });
};

export const updateLanesSettings = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.cagingSettings?.global || !state.editFields.current.schema.lanes) {
    return;
  }

  const lanesCagingSettingsMap = arrToMapByKeys(
    (state.editFields.current.schema?.cagingSettings.perLane?.filter((lane) =>
      isDefined(lane)
    ) as LaneCagingSettings[]) ?? [],
    'laneId'
  );
  state.editFields.current.schema.cagingSettings.perLane = state.editFields.current.schema.lanes.map((lane) => {
    if (!state.editFields.current.schema?.cagingSettings?.global) {
      return null;
    }

    const existedPerLane = lanesCagingSettingsMap?.[lane.id] ?? {};
    const cellToCageDefaultData = lane?.cellTypes?.[0] ?? DEFAULT_CELL_TYPE;
    const cellIndexFromLaneData = lane?.cellTypes?.[0]?.cellIndex ?? null;

    const overridesSettings = {
      ...state.editFields.current.schema.cagingSettings.global,
      ...existedPerLane?.overrideSettings,
    };

    return {
      __typename: 'LaneCagingSettings',
      laneId: lane.id,
      overrideSettings: getParsedCagingSettings(overridesSettings),
      magnification: existedPerLane.magnification ?? DEFAULT_MAGNIFICATION,
      cellToCage: {
        ...cellToCageDefaultData,
        cellIndex: cellIndexFromLaneData,
      },
      cellToSubtract: null,
    };
  });
};

export const getParsedCagingSettings = (settings: CagingSettingsItem) => {
  const objCopy = structuredClone(settings);
  Object.keys(objCopy).forEach((key) => {
    if (isKeyOf(key, objCopy) && objCopy[key] === null && key !== '__typename') {
      objCopy[key] = DEFAULT_GLOBAL_SETTINGS[key];
    }
  });

  return objCopy;
};

// provide for the case when lanes are provided without cell Types, or when the number of cell Types in lane's differs
export const getParsedLanesData = (lanes?: Nullable<Lane[]>) => {
  const lanesCopy = structuredClone(lanes) ?? [];
  const cellTypesCountInPerLane = lanesCopy.map((lane) => lane.cellTypes?.length ?? 0);
  const maxCellTypesLength = Math.max(...cellTypesCountInPerLane);

  lanesCopy.forEach((lane) => {
    const cellTypes = Array.isArray(lane.cellTypes) ? lane.cellTypes : [null];

    const cellTypesLength = cellTypes.length;
    if (cellTypesLength < maxCellTypesLength) {
      lane.cellTypes = cellTypes.concat(Array(maxCellTypesLength - cellTypesLength).fill(null));
    }
  });
  return lanesCopy;
};
