import { AXES_DEFAULT_PRESET } from '@/helpers';
import { channelDataList, isChannelIdInChannelData } from '@/helpers/channels';

export type TAxisChannelSelectOption = {
  axes: {
    xAxis: string;
    yAxis: string;
  };
  value: string;
  label: string;
};

export const prepareAxesPresetOptionList = (channelList: string[], isObjectEntityEnabled: boolean) => {
  const optionList: TAxisChannelSelectOption[] = [];
  channelDataList.forEach((channelData) => {
    const channel =
      (channelData.name !== 'White' || !isObjectEntityEnabled) &&
      channelList.find((channelId) => isChannelIdInChannelData(channelId, channelData));
    if (channel) {
      const axes = isObjectEntityEnabled
        ? {
            xAxis: `${channel}_mean`,
            yAxis: 'object_area_px',
          }
        : {
            xAxis: `${channel}_mean`,
            yAxis: `${channel}_bg_mean`,
          };
      optionList.push({
        axes,
        value: channelData.name,
        label: channelData.name,
      });
    }
  });
  if (isObjectEntityEnabled) {
    optionList.unshift({
      axes: AXES_DEFAULT_PRESET,
      value: 'White',
      label: 'White',
    });
  }
  return optionList;
};
