import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { searchExperiments } from '@/helpers/experiments/searchExperiments';
import { getCount } from '@/helpers/experiments/getExperimentsCount';
import { formatDate } from '@/helpers';
import { timeFilterOptionList } from '@/helpers/tempData/timeFilter';

import useParamsProjectId from '@/hooks/useParamsProjectId';

import { appAPI } from '@/store/services/app';

import ControlPanel from '@/components/Layout/ControlPanel';
import Select from '@/components/common/Select';
import SearchInput from '@/components/common/SearchInput';
import icons from '@/components/common/icons';
import NoDataFound from '@/components/common/NoDataFound';
import Button from '@/components/common/Button';
import CheckboxInput from '@/components/common/CheckboxInput';
import DashboardHeader from '@/components/dashboard/DashboardHeader';
import Metadata from '@/components/common/Metadata';
import ExperimentListItem from '@/pages/ExperimentsDashboard/components/ExperimentListItem';
import ExperimentListItemCard from '@/pages/ExperimentsDashboard/components/ExperimentListItemCard';
import { getFormattedOperators, getFormattedInstruments } from '@/pages/ExperimentsDashboard/helpers';
import MemberList from '@/pages/ProjectList/MemberList';

import styles from './ExperimentList.module.scss';
import VisibleInView from '../VisibleInView';

const cn = classnames.bind(styles);

const SEARCH_PARAM_EMPTY_SCANS = 'emptyScans';
const REQUEST_PARAM_NAME_LIST = ['time', 'operator', 'instrument', 'nextToken', 'projectId'] as const;
type TRequestParamName = (typeof REQUEST_PARAM_NAME_LIST)[number];
type TRequestParamList = Partial<Record<TRequestParamName, string>> & Partial<Record<'containScans', boolean>>;

const ExperimentList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = useParamsProjectId();

  const [cardsType, setCardsType] = useState('card');
  const [instrumentFilterOptionList, setInstrumentFilterOptionList] = useState<TOption[]>([
    { value: '', label: 'All instruments' },
  ]);
  const [operatorFilterOptionList, setOperatorFilterOptionList] = useState<TOption[]>([
    { value: '', label: 'All operators' },
  ]);
  const [hasScans, setHasScans] = useState(() => !searchParams.has(SEARCH_PARAM_EMPTY_SCANS));
  const [searchQuery, setSearchQuery] = useState('');
  const [requestParamList, setRequestParamList] = useState<TRequestParamList>({});
  const [isExperimentListLoadingState, setExperimentListLoadingState] = useState(false);

  let projectData: TProject | undefined;
  let isProjectLoading = false;
  let projectError: FetchBaseQueryError | SerializedError | undefined;
  let isProjectError = false;

  if (projectId) {
    const allProjectData = appAPI.useFetchProjectQuery(projectId);

    projectData = allProjectData.data;
    projectError = allProjectData.error;
    isProjectError = allProjectData.isError;
    isProjectLoading = allProjectData.isFetching && allProjectData.isLoading;
    if (!requestParamList.projectId) {
      requestParamList.projectId = projectId;
    }
  }

  const { data: operators } = appAPI.useFetchExperimentsOperatorsQuery();
  const { data: instruments } = appAPI.useFetchInstrumentsQuery();

  // returns corresponded to request params experiment list even when endpoint is rejected
  // the endpoint can be rejected for the next part but still contain the first part of the list
  const selector = useMemo(() => appAPI.endpoints.fetchExperimentList.select(requestParamList), [requestParamList]);
  const { data } = useSelector(selector);
  const {
    nextToken,
    isFetching: isExperimentListFetching,
    isLoading: isExperimentListLoading,
    isError: isExperimentListError,
    error: experimentListError,
  } = appAPI.useFetchExperimentListQuery(requestParamList, {
    selectFromResult: (result) => ({
      ...result,
      nextToken: result.data?.nextToken,
    }),
  });
  const [fetchAssays] = appAPI.useLazyFetchAssayListQuery();

  const isExperimentListFirstPartFetching = useMemo(
    () => isExperimentListFetching && !nextToken,
    [isExperimentListFetching, nextToken]
  );

  const experimentList = useMemo(() => data?.list ?? [], [data?.list]);

  const setSearchParamItem = (searchParamValue: string, searchParamName: string) => {
    if (searchParamValue) {
      searchParams.set(searchParamName, searchParamValue);
    } else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(searchParams);
  };

  const handleTimeInputChange = (value: string) => {
    const { nextToken: _nextToken, time: _time, ...newRequestParamList } = requestParamList;
    setRequestParamList(value ? { ...newRequestParamList, time: value } : newRequestParamList);
    setSearchParamItem(value, 'time');
  };

  const handleOperatorInputChange = (value: string) => {
    const { nextToken: _nextToken, operator: _operator, ...newRequestParamList } = requestParamList;
    setRequestParamList(value ? { ...newRequestParamList, operator: value } : newRequestParamList);
    setSearchParamItem(value, 'operator');
  };

  const handleInstrumentInputChange = (value: string) => {
    const { nextToken: _nextToken, instrument: _instrument, ...newRequestParamList } = requestParamList;
    setRequestParamList(value ? { ...newRequestParamList, instrument: value } : newRequestParamList);
    setSearchParamItem(value, 'instrument');
  };

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const toggleHasScansInputChange = () => {
    setHasScans((prev) => !prev);
  };

  const handleView = () => {
    if (cardsType === 'panel') {
      setCardsType('card');
    } else {
      setCardsType('panel');
    }
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  const getDashboardHeader = () => {
    const { experimentsCount, instrumentsCount } = getCount(searchQuery, experimentList);

    if (projectId) {
      return (
        <DashboardHeader
          title={projectData?.name ?? 'Unknown'}
          dataList={[
            {
              countData: {
                label: 'Experiments',
                value: experimentsCount,
              },
              customData: (
                <Metadata className={cn('dashboard-header__metadata')}>
                  <Metadata.Item title="Owner" description={projectData?.owner ?? <NoDataFound alignment="left" />} />
                  <Metadata.Item
                    title="Created"
                    description={formatDate(projectData?.dateCreated) ?? <NoDataFound alignment="left" />}
                  />
                </Metadata>
              ),
            },
            {
              customData: (
                <div className={cn('dashboard-header__project-team-data')}>
                  <div className={cn('dashboard-header__project-team-members')}>
                    <MemberList name={projectData?.owner ?? ''} size={40} />
                    <div className={cn('dashboard-header__plus-icon')}>+</div>
                    <MemberList nameList={projectData?.collaborators ?? []} isReverseOrder={false} size={40} />
                  </div>
                  <span>Project team</span>
                </div>
              ),
            },
          ]}
        />
      );
    }

    return (
      <DashboardHeader
        title="Dashboard"
        dataList={[
          { countData: { label: 'Instruments', value: instrumentsCount } },
          { countData: { label: 'Experiments', value: experimentsCount } },
        ]}
      />
    );
  };

  useEffect(() => {
    const loading = isExperimentListFetching || isExperimentListLoading || isProjectLoading;

    /**
     * `setTimeout` used to delay the execution of the `setExperimentListLoadingState` function
     * to avoid issue when the Loading state finished with the empty result [] and starts to loading
     * the next page with nextToken
     */
    const timer = setTimeout(() => {
      setExperimentListLoadingState(() => loading);
    });

    return () => {
      clearTimeout(timer);
    };
  }, [isExperimentListFetching, isExperimentListLoading, isProjectLoading]);

  const getExperimentsCards = () => {
    const foundExperiments = searchExperiments(searchQuery, experimentList);

    if (!isExperimentListLoadingState && !foundExperiments?.length) {
      return <NoDataFound size="normal" alignment="center" />;
    }

    if (cardsType === 'panel') {
      return (
        <div className={cn('dashboard__items_list')}>
          {foundExperiments?.map((experiment: TExperiment) => (
            <VisibleInView key={`${experiment.experimentId}_list`} placeholderClassName={cn('panel-placeholder')}>
              <ExperimentListItem experiment={experiment} searchQuery={searchQuery} />
            </VisibleInView>
          ))}
        </div>
      );
    }

    if (cardsType === 'card') {
      return (
        <div className={cn('dashboard__items_cards')}>
          {foundExperiments?.map((experiment: TExperiment) => (
            <VisibleInView key={`${experiment.experimentId}_list`} placeholderClassName={cn('card-placeholder')}>
              <ExperimentListItemCard experiment={experiment} searchQuery={searchQuery} />
            </VisibleInView>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    fetchAssays();
    const newRequestParamList: TRequestParamList = {};
    REQUEST_PARAM_NAME_LIST.forEach((requestParamName) => {
      if (searchParams.has(requestParamName)) {
        newRequestParamList[requestParamName] = searchParams.get(requestParamName) ?? '';
      }
    });
    setRequestParamList((prev) => ({ ...prev, ...newRequestParamList }));
  }, []);

  useEffect(() => {
    if (!operators?.length) return;
    const formattedOperators = getFormattedOperators(operators);
    setOperatorFilterOptionList(formattedOperators);
  }, [operators]);

  useEffect(() => {
    if (!instruments?.length) return;

    const formattedInstruments = getFormattedInstruments(instruments);
    setInstrumentFilterOptionList(formattedInstruments);
  }, [instruments]);

  useEffect(() => {
    if (isExperimentListError) {
      showErrorToast(getErrorMessage(experimentListError));
    }
  }, [isExperimentListError, experimentListError]);

  useEffect(() => {
    if (isProjectError) {
      showErrorToast(getErrorMessage(projectError));
    }
  }, [isProjectError, projectError]);

  useEffect(() => {
    const newRequestParamList = { ...requestParamList, nextToken };
    if (!shallowEqual(requestParamList, newRequestParamList)) {
      setRequestParamList((prev) => ({ ...prev, nextToken }));
    }
  }, [nextToken]);

  useEffect(() => {
    setSearchParamItem(!hasScans ? '1' : '', SEARCH_PARAM_EMPTY_SCANS);
    const { nextToken: _nextToken, containScans: _containScans, ...newRequestParamList } = requestParamList;
    setRequestParamList({ ...newRequestParamList, containScans: hasScans });
  }, [hasScans]);

  if (isExperimentListError && !nextToken) {
    return <div className={cn('dashboard-data__error')}>{getErrorMessage(experimentListError)}</div>;
  }

  if (isProjectError) {
    return <div className={cn('dashboard-data__error')}>{getErrorMessage(projectError)}</div>;
  }

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content', 'content')}>
        <div>{!isProjectLoading && getDashboardHeader()}</div>
        <ControlPanel isLoading={isExperimentListFetching}>
          <ControlPanel.StickyContent>
            <ControlPanel.FilterList>
              <Select
                placeholder="Time"
                placeholderClassName={cn('content__select-item')}
                theme={themeOptions.light}
                value={requestParamList.time ?? ''}
                onChange={handleTimeInputChange}
                options={timeFilterOptionList}
              />

              <Select
                placeholder="Operator"
                placeholderClassName={cn('content__select-item')}
                theme={themeOptions.light}
                value={requestParamList.operator ?? ''}
                onChange={handleOperatorInputChange}
                options={operatorFilterOptionList}
              />

              <Select
                placeholder="Instrument name"
                placeholderClassName={cn('content__select-item')}
                theme={themeOptions.light}
                value={requestParamList.instrument ?? ''}
                onChange={handleInstrumentInputChange}
                options={instrumentFilterOptionList}
              />

              <CheckboxInput
                checked={hasScans}
                onChange={toggleHasScansInputChange}
                theme="light"
                label="Hide experiments with no scans"
                isSwitch
                switchClassName={cn('content__switch-item')}
              />
            </ControlPanel.FilterList>
          </ControlPanel.StickyContent>

          <ControlPanel.RightActions>
            <SearchInput
              onChange={handleSearchInputChange}
              onReset={handleResetSearchClick}
              value={searchQuery}
              disabled={!experimentList?.length}
              className={cn('content__search')}
            />
            <Button
              tooltip={cardsType === 'panel' ? 'Cards view' : 'Panel view'}
              color="white"
              className={cn('content__button')}
              onClick={handleView}
            >
              {cardsType === 'panel' ? <icons.ChangeViewIcon /> : <icons.CardsViewIcon />}
            </Button>
          </ControlPanel.RightActions>
        </ControlPanel>

        {!isExperimentListFirstPartFetching && getExperimentsCards()}
      </div>
    </div>
  );
};

export default ExperimentList;
