import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TInitialScatterPlotsState,
  EAxesGroupName,
  TAxes,
  TMatrixPlotInfo,
  TBaseScatterPlotsSettings,
} from '@/store/slices/scatterplots/types';

import { experimentActions } from '@/store/slices/experiment';
import { EUnselectedOption } from '@/types/settings';

import { getNewAxesState, getStateObject } from './helpers';

import { chartSettingsActions } from '../chartSettings';

const initialState: TInitialScatterPlotsState = {
  currentAxesGroupName: EAxesGroupName.general,
  axes: {},

  activeEntityUuid: { uuid: '', lastEdit: null },
  densityBandWidth: {
    custom: { x: 0, y: 0 },
    default: { x: 0, y: 0 },
  },

  matrixPlotsInfo: {},
  highlightDotsBy: EUnselectedOption.value,
  specificDatasetOptionMap: {},
};

// TODO: MATRIX_SINGLE_CHART we know, that need change logic of calculate of axes per every plot in matrix view, but dont know how this do, need rework of group names to single chart
const scatterplotsSlice = createSlice({
  name: 'scatterplots',
  initialState,
  reducers: {
    setCurrentAxesGroupName: (state, action: PayloadAction<EAxesGroupName>) => {
      state.currentAxesGroupName = action.payload;
    },
    setAxes: (
      state,
      action: PayloadAction<{
        axesGroupName?: EAxesGroupName;
        chartDataId?: string;
        newAxes: Partial<TAxes>;
        withoutUpdatingGroupName?: boolean;
      }>
    ) => {
      const { axesGroupName, withoutUpdatingGroupName, chartDataId, newAxes } = action.payload;
      if (!newAxes.x && !newAxes.y) {
        return;
      }
      if (axesGroupName && !withoutUpdatingGroupName) {
        state.currentAxesGroupName = axesGroupName;
      }

      const groupName = axesGroupName ?? state.currentAxesGroupName;

      state.axes = getNewAxesState(state.axes, newAxes, groupName, chartDataId);
    },
    clearAxesGroups: (state, action: PayloadAction<EAxesGroupName[]>) => {
      const groupNameList = action.payload;
      const newAxes = { ...state.axes };
      groupNameList.forEach((groupName) => {
        delete newAxes[groupName];
      });
      state.axes = newAxes;
    },
    clearAllAxesGroups: (state) => {
      state.axes = { ...initialState.axes };
    },

    setActiveEntityUuid: (state, action) => {
      state.activeEntityUuid = action.payload;
    },
    setDensityBandWidth: (state, action) => {
      const newCustom = action.payload?.custom ?? {};
      const newDefault = action.payload?.default ?? {};
      state.densityBandWidth = {
        custom: {
          ...state.densityBandWidth.custom,
          ...newCustom,
        },
        default: {
          ...state.densityBandWidth.default,
          ...newDefault,
        },
      };
    },

    addMatrixPlotId: (state, action: PayloadAction<TMatrixPlotInfo>) => {
      if (!action.payload) {
        return;
      }
      state.matrixPlotsInfo[action.payload.plotId] = action.payload;
    },
    removeMatrixPlotId: (state, action: PayloadAction<string>) => {
      delete state.matrixPlotsInfo[action.payload];
    },
    setHighlightDotsBy: (
      sliceState,
      action: PayloadAction<{ highlightBy: string; fullScreenChartDataId?: string }>
    ) => {
      const { highlightBy, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);
      state.highlightDotsBy = highlightBy;
    },
    setSpecificChartSettings: (
      state,
      action: PayloadAction<{ chartDataId: string; settings: Partial<TBaseScatterPlotsSettings> }>
    ) => {
      const { chartDataId, settings } = action.payload;
      if (!state.specificDatasetOptionMap[chartDataId]) return;

      state.specificDatasetOptionMap[chartDataId] = {
        ...state.specificDatasetOptionMap[chartDataId],
        ...settings,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(experimentActions.setCurrentExperimentId, (state) => {
      state.currentAxesGroupName = EAxesGroupName.general;
      state.axes = {};
    });
    builder
      .addCase(chartSettingsActions.setIsObjectEntityEnabled, (state) => {
        state.highlightDotsBy = initialState.highlightDotsBy;
      })
      .addCase(
        chartSettingsActions.setFullScreenChartData,
        (state, action: PayloadAction<Nullable<TDatasetDetails>>) => {
          if (action.payload && !state.specificDatasetOptionMap[action.payload.id]) {
            state.specificDatasetOptionMap[action.payload.id] = {
              highlightDotsBy: state.highlightDotsBy,
            };
          }
        }
      )
      .addCase(
        chartSettingsActions.clearSpecificDatasetOptionMap,
        (state, action: PayloadAction<{ chartDataId?: string }>) => {
          const { chartDataId } = action.payload;

          if (chartDataId && state.specificDatasetOptionMap[chartDataId]) {
            delete state.specificDatasetOptionMap[chartDataId];
          }
        }
      )
      .addCase(chartSettingsActions.clearAllSpecificChartSettings, (state) => {
        state.specificDatasetOptionMap = {};
      })
      .addCase(
        chartSettingsActions.setSpecificChartSettingsToAllDatasets,
        (state, action: PayloadAction<{ fullScreenChartDataId: string }>) => {
          const { fullScreenChartDataId } = action.payload;

          const specificSettings = state.specificDatasetOptionMap?.[fullScreenChartDataId];

          if (!Object.keys(specificSettings)) return;
          state.highlightDotsBy = specificSettings.highlightDotsBy ?? state.highlightDotsBy;
          state.specificDatasetOptionMap = {};
        }
      );
  },
});

export default scatterplotsSlice;
