import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getChannelName, getChannelShaderColor } from '@/helpers/channels';

import {
  TBoundaryOption,
  TChannelListOption,
  TLaneOption,
  TNavigatorState,
  TPositionOption,
  TRenderOption,
  TViewportOption,
  TZoomOption,
} from './types';

const initialState: TNavigatorState = {
  experiments: {},
};

const getInitialOptions = (
  channelIdList: string[],
  laneIdList: string[],
  channels: TChannelListOption,
  viewportSettings: TViewportOption[]
) => {
  const render: Record<string, TRenderOption> = {};
  const lanes: Record<string, TLaneOption> = {};

  laneIdList.forEach((laneId, index) => {
    lanes[laneId] = {
      position: {
        x: 0,
        y: 0,
        lastEdit: '',
      },
      zoom: {
        zoom: 1,
        lastEdit: '',
      },
      viewport: viewportSettings[index],
      boundary: {
        top: 0,
        left: 0,
        right: 1,
        bottom: 1,
      },
    };
  });

  const activeChannelNameList: string[] = [];
  channelIdList.forEach((channelId) => {
    const channelName = channelId.toLowerCase() === 'white.caging' ? channelId : getChannelName(channelId, false);
    const isActive = !activeChannelNameList.includes(channelName);
    if (isActive) {
      activeChannelNameList.push(channelName);
    }
    render[channelId] = {
      label: channelName,
      color: getChannelShaderColor(channelId),
      range: [0, 255],
      isActive,
      isUseLut: false,
      lutType: '',
    };
  });

  return {
    lanes,
    channels,
    currentLaneId: laneIdList[0],
    currentScanId: '',
    render,
  };
};

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState,
  reducers: {
    setInitialChannelOptions: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneIdList: string[];
        channelIdList: string[];
        channels: TChannelListOption;
        viewportSettings: TViewportOption[];
      }>
    ) => {
      const { experimentId, laneIdList = [], channelIdList, channels, viewportSettings } = action.payload;
      if (!channelIdList.length) {
        return;
      }

      if (!state.experiments[experimentId]) {
        state.experiments[experimentId] = getInitialOptions(channelIdList, laneIdList, channels, viewportSettings);
      }
    },
    setZoom: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
        options: Partial<TZoomOption>;
      }>
    ) => {
      const { experimentId, laneId, options } = action.payload;
      const { lanes } = state.experiments[experimentId];
      const lane = lanes[laneId];
      lane.zoom = { ...lane.zoom, ...options };
    },
    setPosition: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
        options: Partial<TPositionOption>;
      }>
    ) => {
      const { experimentId, laneId, options } = action.payload;
      const { lanes } = state.experiments[experimentId];
      const lane = lanes[laneId];
      lane.position = { ...lane.position, ...options };
    },
    setBoundary: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
        boundary: Partial<TBoundaryOption>;
      }>
    ) => {
      const { experimentId, laneId, boundary } = action.payload;
      const { lanes } = state.experiments[experimentId];
      const lane = lanes[laneId];
      lane.boundary = { ...lane.boundary, ...boundary };
    },
    setViewport: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
        viewport: Partial<TLaneViewport>;
      }>
    ) => {
      const { experimentId, laneId, viewport } = action.payload;
      const { lanes } = state.experiments[experimentId];
      const lane = lanes[laneId];
      lane.viewport = { ...lane.viewport, ...viewport };
    },
    setLanesOptions: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
        options: Partial<TLaneOption>;
      }>
    ) => {
      const { experimentId, laneId, options } = action.payload;
      const { lanes } = state.experiments[experimentId];

      lanes[laneId] = { ...lanes[laneId], ...options };
    },
    setRenderOptions: (
      state,
      action: PayloadAction<{
        experimentId: string;
        channelId: string;
        options: Partial<TRenderOption>;
      }>
    ) => {
      const { experimentId, channelId, options } = action.payload;
      const { render } = state.experiments[experimentId];
      render[channelId] = { ...render[channelId], ...options };
    },
    setCurrentLaneId: (
      state,
      action: PayloadAction<{
        experimentId: string;
        laneId: string;
      }>
    ) => {
      const { experimentId, laneId } = action.payload;
      state.experiments[experimentId].currentLaneId = laneId;
    },
    setCurrentScanId: (
      state,
      action: PayloadAction<{
        experimentId: string;
        scanId: string;
      }>
    ) => {
      const { experimentId, scanId } = action.payload;
      if (!state.experiments[experimentId]) {
        return;
      }
      state.experiments[experimentId].currentScanId = scanId;
    },
  },
});

export default navigatorSlice;
