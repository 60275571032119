import { FC, memo, useEffect, useId, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { lexSort } from '@/helpers/arrays';

import { useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import useSorting from '@/hooks/useSorting';

import { datasetsActions, datasetsSelectors } from '@/store/slices/datasets';

import Panel from '@/components/common/Panel';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import SortedFieldHeading from '@/components/common/SortedFieldHeading';

import { ECagesSummary, TCagesSummaryKeys } from '../types';

import LaneDetailsRow from '../LaneDetailsRow';

import styles from './Datasets.module.scss';

const cn = classnames.bind(styles);

type TDatasetsProps = {
  className?: string;
  isMinimalView?: boolean;
};

const Datasets: FC<TDatasetsProps> = ({ className, isMinimalView = false }) => {
  const appDispatch = useAppDispatch();
  const uid = useId();

  const experimentId = useParamsExperimentId();
  const laneDetailsList = useSelector(datasetsSelectors.selectLaneDetailsList);
  const preprocessingDatasets = useSelector(datasetsSelectors.selectPreprocessingDatasets(experimentId));
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const isEditMode = useSelector(datasetsSelectors.selectEditMode);

  const [genomePresetValue, setGenomePresetValue] = useState<string>();
  const [selectedFcConfig, setSelectedFcConfig] = useState('');

  const handleFcConfigChange = (value: string) => {
    setSelectedFcConfig(value);
  };

  const handleGenomeSelect = (genome: string) => {
    setGenomePresetValue(genome);
  };

  const handleEditMode = () => {
    appDispatch(datasetsActions.setEditMode({ isEditMode: !isEditMode }));
  };

  const filteredLaneDetailsList = useMemo<TLaneDetails[]>(() => {
    if (!isPreprocessingView) {
      return laneDetailsList;
    }

    const laneIdsObj = preprocessingDatasets.reduce((acc: Record<string, boolean>, el) => {
      acc[el.laneId] = true;
      return acc;
    }, {});

    const filteredList = laneDetailsList.filter((el) => laneIdsObj[el.id]);

    return filteredList;
  }, [preprocessingDatasets, isPreprocessingView, laneDetailsList]);

  const sortingMethods = {
    sampleName: (a: TLaneDetails, b: TLaneDetails) => lexSort(a.sampleFriendlyName, b.sampleFriendlyName),
    lane: (a: TLaneDetails, b: TLaneDetails) => lexSort(a.letterName, b.letterName),

    default: (a: TLaneDetails, b: TLaneDetails) => lexSort(a.id, b.id),
  };

  const { setSortingSettings, sortedData, sortingField, sortingOrder } = useSorting(
    [...filteredLaneDetailsList],
    sortingMethods
  );

  useEffect(() => {
    appDispatch(datasetsActions.setEditMode({ isEditMode: false }));
  }, []);

  const handleSortingFactory = (field: string) => () => {
    setSortingSettings(field);
  };

  return (
    <Panel
      theme={themeOptions.light}
      className={cn('dataset', { 'dataset_edit-mode': isEditMode, 'dataset_minimal-view': isMinimalView })}
    >
      <Panel.Content className={cn('dataset__panel-content', className)}>
        <div className={cn('dataset__header', 'dataset__wrap-header')}>
          <SortedFieldHeading
            id={`${uid}-sample-name`}
            fieldName="sampleName"
            sortingField={sortingField}
            sortingOrder={sortingOrder}
            onChangeSorting={handleSortingFactory('sampleName')}
            className={cn('dataset__title')}
          >
            Sample name
          </SortedFieldHeading>
          <SortedFieldHeading
            id={`${uid}-lane`}
            fieldName="lane"
            sortingField={sortingField}
            sortingOrder={sortingOrder}
            onChangeSorting={handleSortingFactory('lane')}
            className={cn('dataset__title')}
          >
            Lane
          </SortedFieldHeading>
          {!isMinimalView && (
            <>
              {Object.keys(ECagesSummary).map((key) => (
                <h5 key={key} className={cn('dataset__title')}>
                  {ECagesSummary[key as TCagesSummaryKeys]}
                </h5>
              ))}
              <h5 className={cn('dataset__title')}>Sequencing data</h5>
              <Button
                onClick={handleEditMode}
                className={cn(
                  'dataset__edit-button',
                  { 'dataset__edit-button_selected': isEditMode },
                  { 'dataset__edit-button_hidden': isPreprocessingView }
                )}
                color={isEditMode ? 'dark' : 'light'}
              >
                <icons.EditIcon />
              </Button>
            </>
          )}
        </div>
        {sortedData.map((laneDetails) => (
          <LaneDetailsRow
            key={laneDetails.id}
            laneDetails={laneDetails}
            isMinimalView={isMinimalView}
            genomePresetValue={genomePresetValue}
            handleGenomeSelect={handleGenomeSelect}
            selectedFcConfig={selectedFcConfig}
            handleFcConfigChange={handleFcConfigChange}
          />
        ))}
      </Panel.Content>
    </Panel>
  );
};

export default memo(Datasets);
