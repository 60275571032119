import { AXES_DEFAULT_PRESET, EPreprocessingAssayType } from '@/helpers';

import { TAxes } from '@/store/slices/scatterplots';
import type { TAxisChannelSelectOption } from '@/store/services/app/selectHelpers';
import {
  getAssayByLane,
  getAssayNameByPreprocessingType,
  isLaneProcessed,
  prepareXAxisForCellKillingProcessedDataset,
  prepareXAxisForCytokineProcessedDataset,
} from '@/helpers/preprocessing';

export const getGeneralAxes = (isObjectEntityEnabled: boolean, plotAxesOptionList: TBasicOption[]) => {
  const newAxes: TAxes = {};
  if (isObjectEntityEnabled || !plotAxesOptionList[0]) {
    newAxes.x = AXES_DEFAULT_PRESET.xAxis;
    newAxes.y = AXES_DEFAULT_PRESET.yAxis;
  } else if (plotAxesOptionList.length >= 2) {
    newAxes.x = plotAxesOptionList[0].value as string;
    newAxes.y = plotAxesOptionList[1].value as string;
  }
  return newAxes;
};

export const getBasicAxes = (
  axesPresetOptionList: TAxisChannelSelectOption[],
  plotAxesOptionList: TBasicOption[],
  isObjectEntityEnabled: boolean,
  channelName?: string
) => {
  let newAxes: TAxes = {};
  const axesPresetOption = axesPresetOptionList.find((option) => channelName?.startsWith(option.value));
  if (axesPresetOption) {
    newAxes.x = axesPresetOption.axes.xAxis;
    newAxes.y = axesPresetOption.axes.yAxis;
  } else {
    newAxes = getGeneralAxes(isObjectEntityEnabled, plotAxesOptionList);
  }
  return newAxes;
};

export const getMarker = (lane: TLane, markerName: string, assay?: Nullable<TAssay>) => {
  if (!lane || !assay || !markerName) return '';

  const isCytokineSecretionAssay =
    assay.name === getAssayNameByPreprocessingType(EPreprocessingAssayType.cytokineSecretion);

  if (!isCytokineSecretionAssay) return '';

  const { classes = [] } = lane;
  const marker = classes.find((classItem) => classItem.name === markerName);

  if (marker) return marker.name;

  // required in cases where the user changes the channel on the dataset analysis page
  const firstMatchedMarker = classes.find(
    (classItem) => classItem.processType === EPreprocessingAssayType.cytokineSecretion
  );

  return firstMatchedMarker?.name ?? '';
};

export const getPreprocessedXAxis = (
  scanList: TScan[],
  chartData: TDatasetDetails,
  plotAxesOptionList: TBasicOption[],
  channelName: string,
  markerName: string
) => {
  const lane =
    scanList.find((scan) => scan.id === chartData.scanId)?.lanes.find((laneItem) => laneItem.id === chartData.laneId) ??
    null;

  if (!lane || !channelName) {
    return '';
  }
  const assay = getAssayByLane(lane, channelName);
  if (!assay) return '';

  if (
    isLaneProcessed(lane, assay) &&
    assay?.name === getAssayNameByPreprocessingType(EPreprocessingAssayType.cellKilling)
  ) {
    return prepareXAxisForCellKillingProcessedDataset({
      channel: channelName,
      channels: lane.channels,
      scatterPlotAxesOptions: plotAxesOptionList,
    });
  }

  return prepareXAxisForCytokineProcessedDataset({
    channel: channelName,
    marker: getMarker(lane, markerName, assay),
    channels: lane.channels,
    scatterPlotAxesOptions: plotAxesOptionList,
  });
};
