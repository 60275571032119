import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';

import { numberFromEventValidate } from '@/helpers/validator';
import { isNumber } from '@/helpers';

import TextInput, { TTextInputProps } from '../TextInput';

type TNumberInput = Omit<TTextInputProps, 'onChange' | 'type' | 'value'> & {
  customRegex?: RegExp;
  onChange?: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
};

const NumberInput = forwardRef<HTMLInputElement, TNumberInput>(
  ({ onChange, customRegex, value, min, max, children, ...props }, ref) => {
    const [stringValue, setStringValue] = useState(value.toString());

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newStringValue = numberFromEventValidate(e, customRegex);

        if (typeof newStringValue === 'undefined') {
          return;
        }

        const newNumberValue = parseFloat(newStringValue) || 0;

        if (isNumber(min) && newNumberValue < min) {
          return;
        }

        if (isNumber(max) && newNumberValue > max) {
          return;
        }

        setStringValue(newStringValue || '0');
        onChange?.(newNumberValue);
      },
      [onChange, customRegex, min, max]
    );

    useEffect(() => {
      const stateValue = parseFloat(stringValue) || 0;
      if (value !== stateValue) {
        setStringValue(value.toString());
      }
    }, [value]);

    return (
      <TextInput ref={ref} onChange={handleChange} value={stringValue} {...props}>
        {children}
      </TextInput>
    );
  }
);

NumberInput.displayName = 'NumberInput';

export default NumberInput;
