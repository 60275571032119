/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ZipExperimentDataset = {
  __typename: 'ZipExperimentDataset';
  url: string;
  status: ZippingStatus;
};

export enum ZippingStatus {
  PENDING = 'PENDING',
  READY = 'READY',
}

export type Gate = {
  name: string;
  xDimension: string;
  yDimension: string;
  scanId: string;
  laneId: string;
  properties?: string | null;
  shape: ModelProps;
  gateNodes?: Array<Gate | null> | null;
};

export type ModelProps = {
  type: string;
  model: string;
};

export type ProcessCageLevelAnalysis = {
  __typename: 'ProcessCageLevelAnalysis';
  status: ProcessCageLevelAnalysisStatus;
};

export enum ProcessCageLevelAnalysisStatus {
  PENDING = 'PENDING',
  READY = 'READY',
}

export type ExperimentEventInput = {
  organizationId: string;
  experimentId: string;
  type: string;
  time: string;
  payload: string;
};

export type ExperimentEvent = {
  __typename: 'ExperimentEvent';
  organizationId: string;
  experimentId: string;
  type: string;
  time: string;
  payload: string;
};

export type CreatePrimaryAnalysisGates = {
  __typename: 'CreatePrimaryAnalysisGates';
  status: CreatePrimaryAnalysisGatesStatus;
};

export enum CreatePrimaryAnalysisGatesStatus {
  PENDING = 'PENDING',
  READY = 'READY',
}

export type CreateRunDesignFromTemplateInput = {
  name: string;
  description?: string | null;
  investigatorId: string;
  projectId?: string | null;
  externalLinks?: Array<string> | null;
  organisms?: Array<string> | null;
  templateId?: string | null;
  // Track deprecation in CD-1016
  templateVersionId?: string | null;
  tags?: Array<TagInput> | null;
};

export type TagInput = {
  key: string;
  value: string;
};

export type RunDesign = {
  __typename: 'RunDesign';
  organizationId: string;
  id: string;
  name: string;
  description?: string | null;
  investigatorId: string;
  projectId?: string | null;
  externalLinks?: Array<string> | null;
  organisms?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  publishedAt?: string | null;
  templateVersionId: string;
  wizardStep?: number | null;
  schema: RunDesignSchema;
  tags?: Array<Tag> | null;
};

export type RunDesignSchema = {
  __typename: 'RunDesignSchema';
  components?: Array<Component> | null;
  lanes?: Array<Lane> | null;
  cagingSettings: CagingSettings;
  opticsSettings: OpticsSettings;
};

export type Component = {
  __typename: 'Component';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
};

export type CellKilling = {
  __typename: 'CellKilling';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  wash?: Wash | null;
  baselineScan?: string | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
  pauseAfterScan?: string | null;
};

export type Timing = {
  __typename: 'Timing';
  placement: Placement;
  relativeTo?: string | null;
  offsetInSeconds?: number | null;
};

export enum Placement {
  START = 'START',
  END = 'END',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  SIMULTANEOUS = 'SIMULTANEOUS',
}

export type LaneReagents = {
  __typename: 'LaneReagents';
  laneId: string;
  // Track deprecation in CD-1016
  reagents?: Array<UsedReagent> | null;
  consumables?: Array<ConsumableToUse | null> | null;
};

export type UsedReagent = {
  __typename: 'UsedReagent';
  reagent: Reagent;
  volume?: number | null;
  concentration?: number | null;
  duration?: number | null;
};

export type Reagent = {
  __typename: 'Reagent';
  id: string;
  name: string;
  type: string;
  annotations?: string | null;
  detectionWavelength?: number | null;
  excitationWavelength?: number | null;
  marker?: string | null;
  phenotype?: string | null;
};

export type Consumable = {
  __typename: 'Consumable';
  id: string;
  name: string;
  type: string;
  annotations?: string | null;
};

export type Compound = {
  __typename: 'Compound';
  id: string;
  name: string;
  type: string;
  annotations?: string | null;
};

export type ConsumableToUse = {
  __typename: 'ConsumableToUse';
  consumable: Consumable;
  volume?: number | null;
  concentration?: number | null;
  duration?: number | null;
};

export type Tag = {
  __typename: 'Tag';
  key: string;
  value: string;
};

export type EditableProperty = {
  __typename: 'EditableProperty';
  path: string;
};

export type SubCompIncubation = {
  __typename: 'SubCompIncubation';
  duration: number;
  temperature: number;
  temperatureUnit: TemperatureUnit;
  onDevice: boolean;
};

export enum TemperatureUnit {
  C = 'C',
  F = 'F',
}

export type Wash = {
  __typename: 'Wash';
  duration: number;
  temperature: number;
  temperatureUnit: TemperatureUnit;
  numberOfWashes: number;
};

export type Scan = {
  __typename: 'Scan';
  id: string;
  timing: Timing;
  lagTime: number;
  numberOfScans: number;
  scanEvery?: number | null;
};

export type ScanConfig = {
  __typename: 'ScanConfig';
  numberOfScans: number;
  lagTime: number;
  scanEvery?: number | null;
  scanIds: Array<string>;
};

export type SurfaceReceptorCytokineSecretion = {
  __typename: 'SurfaceReceptorCytokineSecretion';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  wash?: Wash | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
  pauseAfterScan?: string | null;
};

export type SelectiveRetention = {
  __typename: 'SelectiveRetention';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
};

export type CytokineSecretion = {
  __typename: 'CytokineSecretion';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  wash?: Wash | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
  pauseAfterScan?: string | null;
};

export type Incubation = {
  __typename: 'Incubation';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
  hasTreatment?: boolean | null;
};

export type ScanComp = {
  __typename: 'ScanComp';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  partOf?: string | null;
};

export type Treatment = {
  __typename: 'Treatment';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  wash?: Wash | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
  pauseAfterScan?: string | null;
};

export type mRNA = {
  __typename: 'mRNA';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
};

export type CellCaging = {
  __typename: 'CellCaging';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
};

export type SurfaceReceptor = {
  __typename: 'SurfaceReceptor';
  id: string;
  type: string;
  name: string;
  timing: Timing;
  performedOnLanes: Array<LaneReagents>;
  tags?: Array<Tag> | null;
  duration?: number | null;
  pauseAfterCompletion?: boolean | null;
  editableProperties?: Array<EditableProperty> | null;
  incubation?: SubCompIncubation | null;
  wash?: Wash | null;
  // Track deprecation in CD-1016
  scan?: Scan | null;
  scanConfig?: ScanConfig | null;
};

export type Lane = {
  __typename: 'Lane';
  id: string;
  sample?: string | null;
  cellTypes?: Array<CellType | null> | null;
  annotation?: string | null;
  // Track deprecation in CD-1016
  overrideCagingSettings?: CagingSettings | null;
};

export type CellType = {
  __typename: 'CellType';
  name?: string | null;
  cellIndex?: CellIndex | null;
  function: CellFunction;
  otherFunction?: string | null;
  preTreatment?: ConsumableToUse | null;
  preLabeling?: ConsumableToUse | null;
};

export type CellIndex = {
  __typename: 'CellIndex';
  id: string;
  name: string;
  cellLineOrPrimary?: string | null;
  organisms?: string | null;
  morphology?: string | null;
  tissue?: string | null;
  phenotype?: string | null;
  growthProperties?: string | null;
  vendor?: string | null;
};

export enum CellFunction {
  TARGET = 'TARGET',
  EFFECTOR = 'EFFECTOR',
  OTHER = 'OTHER',
}

export type CagingSettings = {
  __typename: 'CagingSettings';
  global: CagingSettingsItem;
  perLane: Array<LaneCagingSettings | null>;
};

export type CagingSettingsItem = {
  __typename: 'CagingSettingsItem';
  cageRadius: number;
  cageWallThickness: number;
  cageToCageDistance: number;
  cellToCageWallMinDistance: number;
  cageWallToOtherObjsMinDistance: number;
  minCellSize: number;
  maxCellSize: number;
  maxNumberOfControlCages: number;
  maxCageAreaFraction?: number | null;
  dmdExposure?: number | null;
  dmdIntensity?: number | null;
  autoFocusCurrent?: number | null;
  autoFocusExposureTime?: number | null;
  zOffset?: number | null;
};

export type LaneCagingSettings = {
  __typename: 'LaneCagingSettings';
  laneId: string;
  magnification: number;
  cellToCage: CellType;
  cellToSubtract?: CellType | null;
  overrideSettings?: CagingSettingsItem | null;
};

export type OpticsSettings = {
  __typename: 'OpticsSettings';
  global?: Array<OpticsSettingsItem> | null;
  advanced?: Array<AdvancedOpticsSettings> | null;
};

export type OpticsSettingsItem = {
  __typename: 'OpticsSettingsItem';
  detectionWavelength: number;
  excitationWavelength: number;
  intensity: number;
  exposure: number;
};

export type AdvancedOpticsSettings = {
  __typename: 'AdvancedOpticsSettings';
  laneId: string;
  scanId: string;
  settings?: Array<OpticsSettingsItem> | null;
};

export type UpdateRunDesignInput = {
  name?: string | null;
  description?: string | null;
  schema?: string | null;
  externalLinks?: Array<string> | null;
  organisms?: Array<string> | null;
  investigatorId?: string | null;
  projectId?: string | null;
  wizardStep?: number | null;
  tags?: Array<TagInput> | null;
};

export type UpdateRunDesignResult = {
  __typename: 'UpdateRunDesignResult';
  success: boolean;
  runDesign?: RunDesign | null;
  errors?: Array<RunDesignError> | null;
};

export type RunDesignError = {
  __typename: 'RunDesignError';
  message: string;
  path: string;
  code: string;
};

export type PublishRunDesignResult = {
  __typename: 'PublishRunDesignResult';
  success: boolean;
  runDesign?: RunDesign | null;
  errors?: Array<RunDesignError> | null;
};

export type CreateRunDesignTemplateInput = {
  name: string;
  description?: string | null;
  tags?: Array<TagInput> | null;
};

export type RunDesignTemplate = {
  __typename: 'RunDesignTemplate';
  id: string;
  name: string;
  description?: string | null;
  authorId: string;
  activeVersionId?: string | null;
  activeVersion?: RunDesignTemplateVersion | null;
  createdAt: string;
  updatedAt: string;
  deprecatedAt?: string | null;
  tags?: Array<Tag> | null;
};

export type RunDesignTemplateVersion = {
  __typename: 'RunDesignTemplateVersion';
  id: string;
  templateId: string;
  description?: string | null;
  authorId: string;
  version: number;
  createdAt: string;
  updatedAt: string;
  publishedAt?: string | null;
  deprecatedAt?: string | null;
  schema: RunDesignSchema;
  tags?: Array<Tag> | null;
};

export type CreateRunDesignTemplateVersionInput = {
  description?: string | null;
  templateId: string;
  schema: string;
  tags?: Array<TagInput> | null;
};

export type PublishRunDesignTemplateVersionResult = {
  __typename: 'PublishRunDesignTemplateVersionResult';
  success: boolean;
  templateVersion?: RunDesignTemplateVersion | null;
  errors?: Array<RunDesignTemplateVersionError> | null;
};

export type RunDesignTemplateVersionError = {
  __typename: 'RunDesignTemplateVersionError';
  code: string;
  message: string;
};

export type ActivateRunDesignTemplateVersionResult = {
  __typename: 'ActivateRunDesignTemplateVersionResult';
  success: boolean;
  templateVersion?: RunDesignTemplateVersion | null;
  errors?: Array<RunDesignTemplateVersionError> | null;
};

export type EnqueueJobRunInput = {
  name: string;
  jobDefinitionId: string;
  jobDefinitionRevision?: number | null;
  containerOverrides?: ContainerOverridesInput | null;
  timeoutInSeconds?: number | null;
  dependsOn?: Array<string> | null;
  tags?: Array<TagInput> | null;
  organizationId?: string | null;
};

export type ContainerOverridesInput = {
  command?: string | null;
  environment?: Array<EnvironmentVariableInput> | null;
};

export type EnvironmentVariableInput = {
  name: string;
  value: string;
};

export type JobRun = {
  __typename: 'JobRun';
  organizationId: string;
  id: string;
  name: string;
  jobDefinition: JobDefinitionReference;
  containerOverrides?: ContainerOverrides | null;
  status: JobRunStatus;
  createdAt: string;
  updatedAt: string;
  startedAt?: string | null;
  endedAt?: string | null;
  timeoutInSeconds?: number | null;
  userId?: string | null;
  cancelReason?: string | null;
  logUrl?: string | null;
  tags?: Array<Tag> | null;
  dependsOn?: Array<string> | null;
  rerunOf?: string | null;
};

export type JobDefinitionReference = {
  __typename: 'JobDefinitionReference';
  id: string;
  revision: number;
};

export type ContainerOverrides = {
  __typename: 'ContainerOverrides';
  command?: string | null;
  environment?: Array<EnvironmentVariable> | null;
};

export type EnvironmentVariable = {
  __typename: 'EnvironmentVariable';
  name: string;
  value: string;
};

export enum JobRunStatus {
  PENDING = 'PENDING',
  ENQUEUED = 'ENQUEUED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELLING = 'CANCELLING',
  TERMINATING = 'TERMINATING',
  CANCELLED = 'CANCELLED',
}

export type JobDefinitionInput = {
  name: string;
  container: ContainerConfigInput;
  timeoutInSeconds?: number | null;
  jobRoleArn?: string | null;
  ephemeralStorageInGiB?: number | null;
};

export type ContainerConfigInput = {
  cpu: string;
  memory: string;
  image: string;
  command?: string | null;
  environment?: Array<EnvironmentVariableInput> | null;
};

export type JobDefinition = {
  __typename: 'JobDefinition';
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  revisions: Array<JobDefinitionRevision>;
};

export type JobDefinitionRevision = {
  __typename: 'JobDefinitionRevision';
  number: number;
  createdAt: string;
  container: ContainerConfig;
  executor: string;
  jobRoleArn?: string | null;
  timeoutInSeconds?: string | null;
  ephemeralStorageInGib?: number | null;
};

export type ContainerConfig = {
  __typename: 'ContainerConfig';
  cpu: string;
  memory: string;
  image: string;
  command?: string | null;
  environment: Array<EnvironmentVariable>;
};

export type JobRunStatusChangedEventInput = {
  organizationId: string;
  id: string;
  jobRunId: string;
  time: string;
  payload: JobRunStatusChangedEventPayloadInput;
};

export type JobRunStatusChangedEventPayloadInput = {
  status: JobRunStatus;
  logUrl?: string | null;
};

export type JobRunStatusChangedEvent = {
  __typename: 'JobRunStatusChangedEvent';
  organizationId: string;
  id: string;
  jobRunId: string;
  time: string;
  payload: JobRunStatusChangedEventPayload;
};

export type JobRunStatusChangedEventPayload = {
  __typename: 'JobRunStatusChangedEventPayload';
  status: JobRunStatus;
  logUrl?: string | null;
};

export type CreateAuditLogInput = {
  action: string;
  description?: string | null;
  organizationId: string;
  service: string;
  scope?: string | null;
  actor?: ActorInput | null;
  context?: ContextInput | null;
  occurredAt: string;
  metadata?: string | null;
  idempotencyKey?: string | null;
};

export type ActorInput = {
  type: string;
  id: string;
  name?: string | null;
  metadata?: string | null;
};

export type ContextInput = {
  sourceIp?: string | null;
  userAgent?: string | null;
  traceId?: string | null;
  metadata?: string | null;
};

export type AuditLog = {
  __typename: 'AuditLog';
  id: string;
  action: string;
  description?: string | null;
  organizationId: string;
  service: string;
  scope?: string | null;
  correlationId?: string | null;
  occurredAt: string;
  registeredAt: string;
  actor: Actor;
  metadata?: string | null;
  details?: string | null;
};

export type Actor = {
  __typename: 'Actor';
  type: string;
  id?: string | null;
  name?: string | null;
  metadata?: string | null;
};

export type ListRunDesignTemplatesInput = {
  includeDeprecated?: boolean | null;
  limit?: number | null;
  after?: string | null;
};

export type ListRunDesignTemplateConnection = {
  __typename: 'ListRunDesignTemplateConnection';
  edges: Array<RunDesignTemplateEdge>;
  pageInfo: PageInfoCursorBased;
};

export type RunDesignTemplateEdge = {
  __typename: 'RunDesignTemplateEdge';
  node: RunDesignTemplate;
};

export type PageInfoCursorBased = {
  __typename: 'PageInfoCursorBased';
  endCursor?: string | null;
  hasNextPage: boolean;
};

export type ListRunDesignTemplateVersionsInput = {
  templateId: string;
  limit?: number | null;
  after?: string | null;
};

export type ListRunDesignTemplateVersionConnection = {
  __typename: 'ListRunDesignTemplateVersionConnection';
  edges: Array<RunDesignTemplateVersionEdge>;
  pageInfo: PageInfoCursorBased;
};

export type RunDesignTemplateVersionEdge = {
  __typename: 'RunDesignTemplateVersionEdge';
  node: RunDesignTemplateVersion;
};

export type ListRunDesignsInput = {
  limit?: number | null;
  after?: string | null;
  statusFilter?: RunDesignsStatus | null;
};

export enum RunDesignsStatus {
  DRAFT = 'DRAFT',
  READY = 'READY',
}

export type ListRunDesignConnection = {
  __typename: 'ListRunDesignConnection';
  edges: Array<RunDesignEdge>;
  pageInfo: PageInfoCursorBased;
};

export type RunDesignEdge = {
  __typename: 'RunDesignEdge';
  node: RunDesign;
};

export type ListJobDefinitionsInput = {
  name?: string | null;
  after?: string | null;
  limit?: number | null;
};

export type ListJobDefinitionsConnection = {
  __typename: 'ListJobDefinitionsConnection';
  edges: Array<JobDefinitionEdge>;
  pageInfo: PageInfoCursorBased;
};

export type JobDefinitionEdge = {
  __typename: 'JobDefinitionEdge';
  node: JobDefinition;
};

export type ListJobRunsInput = {
  jobDefinitionId?: string | null;
  status?: JobRunStatus | null;
  tags?: Array<TagInput> | null;
  after?: string | null;
  limit?: number | null;
};

export type ListJobRunsConnection = {
  __typename: 'ListJobRunsConnection';
  edges: Array<JobRunEdge>;
  pageInfo: PageInfoCursorBased;
};

export type JobRunEdge = {
  __typename: 'JobRunEdge';
  node: JobRun;
};

export type ListJobRunLogsInput = {
  jobRunId: string;
  after?: string | null;
  limit?: number | null;
};

export type ListJobRunLogsConnection = {
  __typename: 'ListJobRunLogsConnection';
  edges: Array<JobRunLogEdge>;
  pageInfo: PageInfoCursorBased;
};

export type JobRunLogEdge = {
  __typename: 'JobRunLogEdge';
  node: JobRunLog;
};

export type JobRunLog = {
  __typename: 'JobRunLog';
  timestamp?: string | null;
  message?: string | null;
  ingestionTime?: string | null;
};

export type AuditLogsFilter = {
  correlationId?: string | null;
  after?: string | null;
  limit?: number | null;
};

export type ListAuditLogsConnection = {
  __typename: 'ListAuditLogsConnection';
  edges: Array<AuditLogEdge>;
  pageInfo: PageInfoCursorBased;
};

export type AuditLogEdge = {
  __typename: 'AuditLogEdge';
  node: AuditLog;
};

export type ZipExperimentDatasetMutationVariables = {
  experimentId: string;
};

export type ZipExperimentDatasetMutation = {
  zipExperimentDataset?: {
    __typename: 'ZipExperimentDataset';
    url: string;
    status: ZippingStatus;
  } | null;
};

export type ProcessCageLevelAnalysisMutationVariables = {
  experimentId: string;
  gate: Array<Gate | null>;
  objectType: string;
};

export type ProcessCageLevelAnalysisMutation = {
  processCageLevelAnalysis?: {
    __typename: 'ProcessCageLevelAnalysis';
    status: ProcessCageLevelAnalysisStatus;
  } | null;
};

export type PublishExperimentEventMutationVariables = {
  event: ExperimentEventInput;
};

export type PublishExperimentEventMutation = {
  publishExperimentEvent: {
    __typename: 'ExperimentEvent';
    organizationId: string;
    experimentId: string;
    type: string;
    time: string;
    payload: string;
  };
};

export type CreatePrimaryAnalysisGatesMutationVariables = {
  organizationId: string;
  experimentId: string;
  scanId?: string | null;
  laneId?: string | null;
};

export type CreatePrimaryAnalysisGatesMutation = {
  createPrimaryAnalysisGates?: {
    __typename: 'CreatePrimaryAnalysisGates';
    status: CreatePrimaryAnalysisGatesStatus;
  } | null;
};

export type CreateRunDesignFromTemplateMutationVariables = {
  input?: CreateRunDesignFromTemplateInput | null;
};

export type CreateRunDesignFromTemplateMutation = {
  createRunDesignFromTemplate?: {
    __typename: 'RunDesign';
    organizationId: string;
    id: string;
    name: string;
    description?: string | null;
    investigatorId: string;
    projectId?: string | null;
    externalLinks?: Array<string> | null;
    organisms?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    publishedAt?: string | null;
    templateVersionId: string;
    wizardStep?: number | null;
    schema: {
      __typename: 'RunDesignSchema';
    };
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type UpdateRunDesignMutationVariables = {
  id: string;
  input?: UpdateRunDesignInput | null;
};

export type UpdateRunDesignMutation = {
  updateRunDesign?: {
    __typename: 'UpdateRunDesignResult';
    success: boolean;
    runDesign?: {
      __typename: 'RunDesign';
      organizationId: string;
      id: string;
      name: string;
      description?: string | null;
      investigatorId: string;
      projectId?: string | null;
      externalLinks?: Array<string> | null;
      organisms?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      templateVersionId: string;
      wizardStep?: number | null;
    } | null;
    errors?: Array<{
      __typename: 'RunDesignError';
      message: string;
      path: string;
      code: string;
    }> | null;
  } | null;
};

export type PublishRunDesignMutationVariables = {
  id: string;
};

export type PublishRunDesignMutation = {
  publishRunDesign?: {
    __typename: 'PublishRunDesignResult';
    success: boolean;
    runDesign?: {
      __typename: 'RunDesign';
      organizationId: string;
      id: string;
      name: string;
      description?: string | null;
      investigatorId: string;
      projectId?: string | null;
      externalLinks?: Array<string> | null;
      organisms?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      templateVersionId: string;
      wizardStep?: number | null;
    } | null;
    errors?: Array<{
      __typename: 'RunDesignError';
      message: string;
      path: string;
      code: string;
    }> | null;
  } | null;
};

export type CreateRunDesignTemplateMutationVariables = {
  input?: CreateRunDesignTemplateInput | null;
};

export type CreateRunDesignTemplateMutation = {
  createRunDesignTemplate?: {
    __typename: 'RunDesignTemplate';
    id: string;
    name: string;
    description?: string | null;
    authorId: string;
    activeVersionId?: string | null;
    activeVersion?: {
      __typename: 'RunDesignTemplateVersion';
      id: string;
      templateId: string;
      description?: string | null;
      authorId: string;
      version: number;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      deprecatedAt?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    deprecatedAt?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type CreateRunDesignTemplateVersionMutationVariables = {
  input?: CreateRunDesignTemplateVersionInput | null;
};

export type CreateRunDesignTemplateVersionMutation = {
  createRunDesignTemplateVersion?: {
    __typename: 'RunDesignTemplateVersion';
    id: string;
    templateId: string;
    description?: string | null;
    authorId: string;
    version: number;
    createdAt: string;
    updatedAt: string;
    publishedAt?: string | null;
    deprecatedAt?: string | null;
    schema: {
      __typename: 'RunDesignSchema';
    };
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type PublishRunDesignTemplateVersionMutationVariables = {
  id: string;
};

export type PublishRunDesignTemplateVersionMutation = {
  publishRunDesignTemplateVersion?: {
    __typename: 'PublishRunDesignTemplateVersionResult';
    success: boolean;
    templateVersion?: {
      __typename: 'RunDesignTemplateVersion';
      id: string;
      templateId: string;
      description?: string | null;
      authorId: string;
      version: number;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      deprecatedAt?: string | null;
    } | null;
    errors?: Array<{
      __typename: 'RunDesignTemplateVersionError';
      code: string;
      message: string;
    }> | null;
  } | null;
};

export type ActivateRunDesignTemplateVersionMutationVariables = {
  id: string;
};

export type ActivateRunDesignTemplateVersionMutation = {
  activateRunDesignTemplateVersion?: {
    __typename: 'ActivateRunDesignTemplateVersionResult';
    success: boolean;
    templateVersion?: {
      __typename: 'RunDesignTemplateVersion';
      id: string;
      templateId: string;
      description?: string | null;
      authorId: string;
      version: number;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      deprecatedAt?: string | null;
    } | null;
    errors?: Array<{
      __typename: 'RunDesignTemplateVersionError';
      code: string;
      message: string;
    }> | null;
  } | null;
};

export type EnqueueJobRunMutationVariables = {
  input: EnqueueJobRunInput;
};

export type EnqueueJobRunMutation = {
  enqueueJobRun?: {
    __typename: 'JobRun';
    organizationId: string;
    id: string;
    name: string;
    jobDefinition: {
      __typename: 'JobDefinitionReference';
      id: string;
      revision: number;
    };
    containerOverrides?: {
      __typename: 'ContainerOverrides';
      command?: string | null;
    } | null;
    status: JobRunStatus;
    createdAt: string;
    updatedAt: string;
    startedAt?: string | null;
    endedAt?: string | null;
    timeoutInSeconds?: number | null;
    userId?: string | null;
    cancelReason?: string | null;
    logUrl?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
    dependsOn?: Array<string> | null;
    rerunOf?: string | null;
  } | null;
};

export type CancelJobRunMutationVariables = {
  id: string;
  reason: string;
};

export type CancelJobRunMutation = {
  cancelJobRun?: {
    __typename: 'JobRun';
    organizationId: string;
    id: string;
    name: string;
    jobDefinition: {
      __typename: 'JobDefinitionReference';
      id: string;
      revision: number;
    };
    containerOverrides?: {
      __typename: 'ContainerOverrides';
      command?: string | null;
    } | null;
    status: JobRunStatus;
    createdAt: string;
    updatedAt: string;
    startedAt?: string | null;
    endedAt?: string | null;
    timeoutInSeconds?: number | null;
    userId?: string | null;
    cancelReason?: string | null;
    logUrl?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
    dependsOn?: Array<string> | null;
    rerunOf?: string | null;
  } | null;
};

export type TerminateJobRunMutationVariables = {
  id: string;
  reason: string;
};

export type TerminateJobRunMutation = {
  terminateJobRun?: {
    __typename: 'JobRun';
    organizationId: string;
    id: string;
    name: string;
    jobDefinition: {
      __typename: 'JobDefinitionReference';
      id: string;
      revision: number;
    };
    containerOverrides?: {
      __typename: 'ContainerOverrides';
      command?: string | null;
    } | null;
    status: JobRunStatus;
    createdAt: string;
    updatedAt: string;
    startedAt?: string | null;
    endedAt?: string | null;
    timeoutInSeconds?: number | null;
    userId?: string | null;
    cancelReason?: string | null;
    logUrl?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
    dependsOn?: Array<string> | null;
    rerunOf?: string | null;
  } | null;
};

export type RerunJobRunMutationVariables = {
  id: string;
};

export type RerunJobRunMutation = {
  rerunJobRun?: {
    __typename: 'JobRun';
    organizationId: string;
    id: string;
    name: string;
    jobDefinition: {
      __typename: 'JobDefinitionReference';
      id: string;
      revision: number;
    };
    containerOverrides?: {
      __typename: 'ContainerOverrides';
      command?: string | null;
    } | null;
    status: JobRunStatus;
    createdAt: string;
    updatedAt: string;
    startedAt?: string | null;
    endedAt?: string | null;
    timeoutInSeconds?: number | null;
    userId?: string | null;
    cancelReason?: string | null;
    logUrl?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
    dependsOn?: Array<string> | null;
    rerunOf?: string | null;
  } | null;
};

export type CreateJobDefinitionMutationVariables = {
  jobConfig: JobDefinitionInput;
  id?: string | null;
};

export type CreateJobDefinitionMutation = {
  createJobDefinition?: {
    __typename: 'JobDefinition';
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    revisions: Array<{
      __typename: 'JobDefinitionRevision';
      number: number;
      createdAt: string;
      executor: string;
      jobRoleArn?: string | null;
      timeoutInSeconds?: string | null;
      ephemeralStorageInGib?: number | null;
    }>;
  } | null;
};

export type PublishJobRunStatusEventMutationVariables = {
  event: JobRunStatusChangedEventInput;
};

export type PublishJobRunStatusEventMutation = {
  publishJobRunStatusEvent: {
    __typename: 'JobRunStatusChangedEvent';
    organizationId: string;
    id: string;
    jobRunId: string;
    time: string;
    payload: {
      __typename: 'JobRunStatusChangedEventPayload';
      status: JobRunStatus;
      logUrl?: string | null;
    };
  };
};

export type CreateAuditLogMutationVariables = {
  input: CreateAuditLogInput;
};

export type CreateAuditLogMutation = {
  createAuditLog: {
    __typename: 'AuditLog';
    id: string;
    action: string;
    description?: string | null;
    organizationId: string;
    service: string;
    scope?: string | null;
    correlationId?: string | null;
    occurredAt: string;
    registeredAt: string;
    actor: {
      __typename: 'Actor';
      type: string;
      id?: string | null;
      name?: string | null;
      metadata?: string | null;
    };
    metadata?: string | null;
    details?: string | null;
  };
};

export type DummyQueryVariables = {};

export type DummyQuery = {
  dummy?: string | null;
};

export type RunDesignTemplatesQueryVariables = {
  input?: ListRunDesignTemplatesInput | null;
};

export type RunDesignTemplatesQuery = {
  runDesignTemplates?: {
    __typename: 'ListRunDesignTemplateConnection';
    edges: Array<{
      __typename: 'RunDesignTemplateEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type RunDesignTemplateQueryVariables = {
  id: string;
};

export type RunDesignTemplateQuery = {
  runDesignTemplate?: {
    __typename: 'RunDesignTemplate';
    id: string;
    name: string;
    description?: string | null;
    authorId: string;
    activeVersionId?: string | null;
    activeVersion?: {
      __typename: 'RunDesignTemplateVersion';
      id: string;
      templateId: string;
      description?: string | null;
      authorId: string;
      version: number;
      createdAt: string;
      updatedAt: string;
      publishedAt?: string | null;
      deprecatedAt?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    deprecatedAt?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type RunDesignTemplateVersionsQueryVariables = {
  input: ListRunDesignTemplateVersionsInput;
};

export type RunDesignTemplateVersionsQuery = {
  runDesignTemplateVersions?: {
    __typename: 'ListRunDesignTemplateVersionConnection';
    edges: Array<{
      __typename: 'RunDesignTemplateVersionEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type RunDesignTemplateVersionQueryVariables = {
  id: string;
};

export type RunDesignTemplateVersionQuery = {
  runDesignTemplateVersion?: {
    __typename: 'RunDesignTemplateVersion';
    id: string;
    templateId: string;
    description?: string | null;
    authorId: string;
    version: number;
    createdAt: string;
    updatedAt: string;
    publishedAt?: string | null;
    deprecatedAt?: string | null;
    schema: {
      __typename: 'RunDesignSchema';
    };
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type RunDesignQueryVariables = {
  id: string;
};

export type RunDesignQuery = {
  runDesign?: {
    __typename: 'RunDesign';
    organizationId: string;
    id: string;
    name: string;
    description?: string | null;
    investigatorId: string;
    projectId?: string | null;
    externalLinks?: Array<string> | null;
    organisms?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    publishedAt?: string | null;
    templateVersionId: string;
    wizardStep?: number | null;
    schema: {
      __typename: 'RunDesignSchema';
    };
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type RunDesignsQueryVariables = {
  input?: ListRunDesignsInput | null;
};

export type RunDesignsQuery = {
  runDesigns?: {
    __typename: 'ListRunDesignConnection';
    edges: Array<{
      __typename: 'RunDesignEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type JobDefinitionsQueryVariables = {
  input?: ListJobDefinitionsInput | null;
};

export type JobDefinitionsQuery = {
  jobDefinitions?: {
    __typename: 'ListJobDefinitionsConnection';
    edges: Array<{
      __typename: 'JobDefinitionEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type JobDefinitionQueryVariables = {
  id: string;
};

export type JobDefinitionQuery = {
  jobDefinition?: {
    __typename: 'JobDefinition';
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    revisions: Array<{
      __typename: 'JobDefinitionRevision';
      number: number;
      createdAt: string;
      executor: string;
      jobRoleArn?: string | null;
      timeoutInSeconds?: string | null;
      ephemeralStorageInGib?: number | null;
    }>;
  } | null;
};

export type JobRunsQueryVariables = {
  input?: ListJobRunsInput | null;
};

export type JobRunsQuery = {
  jobRuns?: {
    __typename: 'ListJobRunsConnection';
    edges: Array<{
      __typename: 'JobRunEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type JobRunQueryVariables = {
  id: string;
};

export type JobRunQuery = {
  jobRun?: {
    __typename: 'JobRun';
    organizationId: string;
    id: string;
    name: string;
    jobDefinition: {
      __typename: 'JobDefinitionReference';
      id: string;
      revision: number;
    };
    containerOverrides?: {
      __typename: 'ContainerOverrides';
      command?: string | null;
    } | null;
    status: JobRunStatus;
    createdAt: string;
    updatedAt: string;
    startedAt?: string | null;
    endedAt?: string | null;
    timeoutInSeconds?: number | null;
    userId?: string | null;
    cancelReason?: string | null;
    logUrl?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      key: string;
      value: string;
    }> | null;
    dependsOn?: Array<string> | null;
    rerunOf?: string | null;
  } | null;
};

export type JobRunLogsQueryVariables = {
  input?: ListJobRunLogsInput | null;
};

export type JobRunLogsQuery = {
  jobRunLogs?: {
    __typename: 'ListJobRunLogsConnection';
    edges: Array<{
      __typename: 'JobRunLogEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type AuditLogQueryVariables = {
  id: string;
};

export type AuditLogQuery = {
  auditLog?: {
    __typename: 'AuditLog';
    id: string;
    action: string;
    description?: string | null;
    organizationId: string;
    service: string;
    scope?: string | null;
    correlationId?: string | null;
    occurredAt: string;
    registeredAt: string;
    actor: {
      __typename: 'Actor';
      type: string;
      id?: string | null;
      name?: string | null;
      metadata?: string | null;
    };
    metadata?: string | null;
    details?: string | null;
  } | null;
};

export type AuditLogsQueryVariables = {
  input?: AuditLogsFilter | null;
};

export type AuditLogsQuery = {
  auditLogs?: {
    __typename: 'ListAuditLogsConnection';
    edges: Array<{
      __typename: 'AuditLogEdge';
    }>;
    pageInfo: {
      __typename: 'PageInfoCursorBased';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type OnExperimentEventSubscriptionVariables = {
  organizationId: string;
  experimentId: string;
  type?: string | null;
};

export type OnExperimentEventSubscription = {
  onExperimentEvent?: {
    __typename: 'ExperimentEvent';
    organizationId: string;
    experimentId: string;
    type: string;
    time: string;
    payload: string;
  } | null;
};

export type JobRunStatusChangedSubscriptionVariables = {
  organizationId: string;
  jobRunId?: string | null;
};

export type JobRunStatusChangedSubscription = {
  jobRunStatusChanged?: {
    __typename: 'JobRunStatusChangedEvent';
    organizationId: string;
    id: string;
    jobRunId: string;
    time: string;
    payload: {
      __typename: 'JobRunStatusChangedEventPayload';
      status: JobRunStatus;
      logUrl?: string | null;
    };
  } | null;
};
