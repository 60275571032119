import { select as d3Select } from 'd3-selection';
import { TGateLabel } from '@/pages/Dataset/components/DatasetChart/types';
import { generateLine } from '@/hooks/gates/helpers/polygonGates';
import { TSelectionGroupElement } from '@/hooks/gates/types';
import { TRenderOnSVGInput } from './types';

const TITILE_OFFSET = 20;
const TITLE_GAP = 6;

export const drawLabelOnSVG = ({
  gateGroup,
  label,
  labelRenderingElement,
}: {
  gateGroup: TSelectionGroupElement;
  label: Nullable<TGateLabel>;
  labelRenderingElement: Nullable<HTMLSpanElement>;
}) => {
  if (!label || !labelRenderingElement) {
    return;
  }

  labelRenderingElement.innerHTML = label.text;

  const { height: labelHeight, width: labelWidth } = labelRenderingElement.getBoundingClientRect();

  const labelX = label.rtl ? label.position.x - labelWidth : label.position.x;
  const labelY = label.position.y;

  const labelContainer = gateGroup.append('g');
  const labelBgX = labelX - TITILE_OFFSET / 2;
  const labelBgY = labelY - labelHeight + TITLE_GAP / 2;
  const bgWidth = labelWidth + TITILE_OFFSET * 2;
  const bgHeight = labelHeight + TITLE_GAP;

  labelContainer
    .append('rect')
    .attr('x', labelBgX)
    .attr('y', labelBgY)
    .attr('rx', 15)
    .style('fill', '#f5f5f5')
    .attr('width', bgWidth)
    .attr('height', bgHeight);

  labelContainer
    .append('text')
    .text(label.text)
    .attr('x', labelX)
    .attr('y', labelY)
    .attr('font-size', 17)
    .style('font-weight', '400')
    .style('font-family', '"SF Pro Display", Arial, sans-serif');
};

export const renderEllipseGateOnSVG = ({
  svg,
  gate,
  imageHeight,
  imageWidth,
  offset,
  labelElement,
}: TRenderOnSVGInput) => {
  if (!gate || gate.type !== 'ellipse') {
    return;
  }

  const {
    label,
    coordinates: { cx, cy, rx, ry },
  } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const group = d3Select(svg).append('g').attr('class', 'gates');

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabelOnSVG({ gateGroup: group, label, labelRenderingElement: labelElement });
  }

  group
    .append('ellipse')
    .attr('class', 'gate-element')
    .attr('cx', Math.round(sx + imageWidth * cx))
    .attr('cy', Math.round(sy + imageHeight * cy))
    .attr('rx', Math.round(imageWidth * rx))
    .attr('ry', Math.round(imageHeight * ry))
    .style('stroke-dasharray', '2, 2')
    .style('stroke', gate.styles.stroke ?? 'black')
    .style('stroke-width', '2')
    .style('fill', 'rgba(0, 0, 0, 0)');
};

export const renderPolarGateOnSVG = ({
  svg,
  gate,
  imageHeight,
  imageWidth,
  offset,
  labelElement,
}: TRenderOnSVGInput) => {
  if (!gate || (gate.type !== 'polar' && gate.type !== 'split-gate')) {
    return;
  }

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const { labelList, coordinates } = gate;

  const group = d3Select(svg).append('g').attr('class', 'gates');

  if (labelList.length) {
    labelList?.forEach((label) => {
      if (!label) {
        return;
      }
      label.position = {
        x: sx + imageWidth * label.position.x,
        y: sy + imageHeight * label.position.y,
      };

      drawLabelOnSVG({ gateGroup: group, label, labelRenderingElement: labelElement });
    });
  }

  coordinates.forEach((line: { x1: number; x2: number; y1: number; y2: number }) => {
    group
      .append('line')
      .attr('x1', Math.round(sx + imageWidth * line.x1))
      .attr('x2', Math.round(sx + imageWidth * line.x2))
      .attr('y1', Math.round(sy + imageHeight * line.y1))
      .attr('y2', Math.round(sy + imageHeight * line.y2))
      .style('stroke-dasharray', '2, 2')
      .style('stroke', gate.styles.stroke ?? 'black')
      .style('stroke-width', '2')
      .style('fill', 'rgba(0, 0, 0, 0)');
  });
};

export const renderPolygonGateOnSVG = ({
  svg,
  gate,
  imageHeight,
  imageWidth,
  offset,
  labelElement,
}: TRenderOnSVGInput) => {
  if (!gate || gate.type !== 'polygon') {
    return;
  }

  const {
    label,
    coordinates: { path },
  } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const group = d3Select(svg).append('g').attr('class', 'gates');

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabelOnSVG({ gateGroup: group, label, labelRenderingElement: labelElement });
  }

  const offsettedPoints = path.map(([x, y]) => ({
    x: Math.round(sx + imageWidth * x),
    y: Math.round(sy + imageHeight * y),
  }));

  group
    .append('path')
    .attr('d', generateLine(offsettedPoints))
    .style('stroke-dasharray', '2, 2')
    .style('stroke', gate.styles.stroke ?? 'black')
    .style('stroke-width', '2')
    .style('fill', 'rgba(0, 0, 0, 0)');
};

export const renderRangeGateOnSVG = ({
  svg,
  gate,
  imageHeight,
  imageWidth,
  offset,
  labelElement,
}: TRenderOnSVGInput) => {
  if (!gate || gate.type !== 'range') {
    return;
  }

  const { label, coordinates } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const group = d3Select(svg).append('g').attr('class', 'gates');

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabelOnSVG({ gateGroup: group, label, labelRenderingElement: labelElement });
  }

  coordinates.forEach((line: { x1: number; x2: number; y1: number; y2: number }) => {
    group
      .append('line')
      .attr('x1', Math.round(sx + imageWidth * line.x1))
      .attr('x2', Math.round(sx + imageWidth * line.x2))
      .attr('y1', Math.round(sy + imageHeight * line.y1))
      .attr('y2', Math.round(sy + imageHeight * line.y2))
      .style('stroke-dasharray', '2, 2')
      .style('stroke', gate.styles.stroke ?? 'black')
      .style('stroke-width', '2')
      .style('fill', 'rgba(0, 0, 0, 0)');
  });
};

export const renderRectangleGateOnSVG = ({
  svg,
  gate,
  imageHeight,
  imageWidth,
  offset,
  labelElement,
}: TRenderOnSVGInput) => {
  if (!gate || gate.type !== 'rectangle') {
    return;
  }

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const {
    label,
    coordinates: { x, y, width, height },
  } = gate;

  const group = d3Select(svg).append('g').attr('class', 'gates');

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabelOnSVG({ gateGroup: group, label, labelRenderingElement: labelElement });
  }

  group
    .append('rect')
    .attr('x', Math.round(sx + imageWidth * x))
    .attr('y', Math.round(sy + imageHeight * y))
    .attr('width', Math.round(imageWidth * width))
    .attr('height', Math.round(imageHeight * height))
    .style('stroke-dasharray', '2, 2')
    .style('stroke', gate.styles.stroke ?? 'black')
    .style('stroke-width', '2')
    .style('fill', 'rgba(0, 0, 0, 0)');
};
