import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { cdnAPI } from '@/store/services/cdnData';
import { EAxesScaleType, EPageWithChartType, EChartType } from '@/types/charts';
import { getEntityLevelAxesOptions } from '@/helpers/dimensions';
import { formatRange, isSameRanges } from '@/helpers/charts/ranges';

import {
  EEntityObjectType,
  EPlotRangeType,
  TBaseChartSettings,
  TCustomRange,
  TInitialChartSettingsState,
} from './types';
import { experimentActions } from '../experiment';
import { getStateObject } from './helpers';

export const initialState: TInitialChartSettingsState = {
  currentChartType: EChartType.dotDensity,
  axesScaleTypes: {
    x: EAxesScaleType.log,
    y: EAxesScaleType.log,
  },
  objectType: EEntityObjectType.all,
  currentColorScale: 'turbo',
  isTickLabelsVisible: true,
  contourBandWidth: 20,
  cageLevelAxesOptionListByLanes: {},
  objectLevelAxesOptionListByLanes: {},
  isObjectEntityEnabled: true,
  isCageLvlForced: false,
  customRangesMap: {},
  customRangesMapName: '',
  customPlotRange: null,
  plotRangesMap: {},
  plotRangesMapName: '',
  lastPlotRangeName: '',
  specificDatasetOptionMap: {},
  fullScreenChartData: null,
  сhartPresetSettingsСompletionStatusMap: {
    [EPageWithChartType.singleChart]: false,
    [EPageWithChartType.matrixView]: false,
    [EPageWithChartType.multiHistogram]: false,
    [EPageWithChartType.knee]: false,
    [EPageWithChartType.violin]: false,
    [EPageWithChartType.heatmap]: false,
    [EPageWithChartType.preprocessing]: false,
  },
};

const chartSettingsSlice = createSlice({
  name: 'chartSettings',
  initialState,
  reducers: {
    setCurrentChartType: (sliceState, action) => {
      const state = getStateObject(sliceState);
      state.currentChartType = action.payload;
      state.customRangesMapName = '';
    },
    setCurrentColorScale: (sliceState, action) => {
      const state = getStateObject(sliceState);
      state.currentColorScale = action.payload;
    },
    setIsObjectEntityEnabled: (sliceState, action: PayloadAction<boolean>) => {
      const state = getStateObject(sliceState);
      state.isObjectEntityEnabled = action.payload;
      state.customRangesMapName = '';
    },
    setIsCageLvlForced: (sliceState, action: PayloadAction<boolean>) => {
      const state = getStateObject(sliceState);
      state.isCageLvlForced = action.payload;
    },
    addCustomPlotRange: (sliceState, action: PayloadAction<{ customRange: TCustomRange; isUnique?: boolean }>) => {
      const state = getStateObject(sliceState);
      const { customRange, isUnique } = action.payload;
      if (!customRange.rangeName) {
        return;
      }

      if (isUnique && state.customRangesMap?.[customRange.rangeName]) {
        return;
      }
      if (!state.customRangesMap) {
        state.customRangesMap = {};
      }
      state.customRangesMap[customRange.rangeName] = customRange;
    },
    deleteCustomPlotRange: (sliceState, action: PayloadAction<string>) => {
      const state = getStateObject(sliceState);
      if (!state.customRangesMap) {
        state.customRangesMap = {};
      }
      delete state.customRangesMap[action.payload];
    },
    updateCustomPlotRange: (
      sliceState,
      action: PayloadAction<{ rangeName: string; updatedData: Partial<TCustomRange> }>
    ) => {
      const state = getStateObject(sliceState);
      const { rangeName, updatedData } = action.payload;
      if (!state.customRangesMap) {
        state.customRangesMap = {};
      }
      state.customRangesMap[rangeName] = {
        ...state.customRangesMap[rangeName],
        ...updatedData,
      };
    },
    setCustomRangeName: (sliceState, action: PayloadAction<string>) => {
      const state = getStateObject(sliceState);
      if (!state.customRangesMap) {
        state.customRangesMap = {};
      }
      const isRangeExist = state.customRangesMap[action.payload];
      state.customRangesMapName = isRangeExist ? action.payload : '';
    },
    clearCustomRanges: (sliceState) => {
      const state = getStateObject(sliceState);
      state.customRangesMap = {};
      state.customRangesMapName = '';
    },
    setPlotRange: (sliceState, action: PayloadAction<{ range: Nullable<TPopulationRange>; rangeName?: string }>) => {
      const state = getStateObject(sliceState);
      if (!state.plotRangesMap) {
        state.plotRangesMap = {};
      }
      const { rangeName: payloadRangeName, range } = action.payload;
      const rangeName = payloadRangeName || state.plotRangesMapName || EPlotRangeType.general;
      let newRange = range;

      if (
        rangeName !== EPlotRangeType.general &&
        (payloadRangeName || state.plotRangesMapName) &&
        newRange &&
        state.plotRangesMap[rangeName]
      ) {
        newRange = {
          xMax: Math.max(state.plotRangesMap[rangeName]?.xMax ?? 0, newRange.xMax),
          yMax: Math.max(state.plotRangesMap[rangeName]?.yMax ?? 0, newRange.yMax),
          xMin: Math.min(state.plotRangesMap[rangeName]?.xMin ?? 0, newRange.xMin),
          yMin: Math.min(state.plotRangesMap[rangeName]?.yMin ?? 0, newRange.yMin),
        };
      }

      if (isSameRanges(formatRange(newRange), formatRange(state.plotRangesMap[rangeName]))) {
        return;
      }

      state.lastPlotRangeName = rangeName;
      state.plotRangesMap[rangeName] = newRange;
    },
    setPlotRangesMapName: (sliceState, action: PayloadAction<string>) => {
      const state = getStateObject(sliceState);
      if (!state.plotRangesMap) {
        state.plotRangesMap = {};
      }
      const plotRangeName = action.payload;
      state.plotRangesMapName = plotRangeName;
      if (!plotRangeName) {
        return;
      }

      state.plotRangesMap[plotRangeName] = null;
    },
    addPlotRange: (sliceState, action: PayloadAction<{ range: Nullable<TPopulationRange>; rangeName: string }>) => {
      const state = getStateObject(sliceState);
      if (!state.plotRangesMap) {
        state.plotRangesMap = {};
      }
      const { range, rangeName } = action.payload;
      state.plotRangesMap[rangeName] = range;
    },
    deletePlotRange: (sliceState, action: PayloadAction<string>) => {
      const state = getStateObject(sliceState);
      if (!state.plotRangesMap) {
        state.plotRangesMap = {};
      }
      delete state.plotRangesMap[action.payload];
    },
    setLastPlotRangeName: (sliceState, action: PayloadAction<string>) => {
      const state = getStateObject(sliceState);
      state.lastPlotRangeName = action.payload;
    },
    clearRanges: (sliceState) => {
      const state = getStateObject(sliceState);
      state.lastPlotRangeName = '';
      state.plotRangesMap = {};
      state.plotRangesMapName = '';
      state.customRangesMap = {};
      state.customRangesMapName = '';
    },
    setAxesScaleTypes: (sliceState, action: PayloadAction<Partial<TInitialChartSettingsState['axesScaleTypes']>>) => {
      const state = getStateObject(sliceState);
      if (!state.axesScaleTypes) {
        state.axesScaleTypes = {
          x: sliceState.axesScaleTypes.x,
          y: sliceState.axesScaleTypes.y,
        };
      }
      state.axesScaleTypes = {
        ...state.axesScaleTypes,
        ...action.payload,
      };
      state.customRangesMapName = '';
    },
    setFullScreenChartData: (state, action: PayloadAction<Nullable<TDatasetDetails>>) => {
      state.fullScreenChartData = action.payload;
      if (action.payload && !state.specificDatasetOptionMap[action.payload.id]) {
        state.specificDatasetOptionMap[action.payload.id] = {
          isTickLabelsVisible: state.isTickLabelsVisible,
        };
      }
    },
    clearFullScreenChartData: (state) => {
      state.fullScreenChartData = null;
    },
    clearSpecificDatasetOptionMap: (state, action: PayloadAction<{ chartDataId?: string }>) => {
      const { chartDataId } = action.payload;

      if (chartDataId && state.specificDatasetOptionMap[chartDataId]) {
        delete state.specificDatasetOptionMap[chartDataId];
      }
    },
    clearAllSpecificChartSettings: (state) => {
      if (!state.fullScreenChartData && Object.keys(state.specificDatasetOptionMap).length) {
        state.specificDatasetOptionMap = {};
      }
    },
    setSpecificChartSettings: (
      state,
      action: PayloadAction<{ chartDataId: string; settings: Partial<TBaseChartSettings> }>
    ) => {
      const { chartDataId, settings } = action.payload;
      if (!state.specificDatasetOptionMap[chartDataId]) return;

      state.specificDatasetOptionMap[chartDataId] = {
        ...state.specificDatasetOptionMap[chartDataId],
        ...settings,
      };
    },
    setObjectType: (sliceState, action: PayloadAction<TInitialChartSettingsState['objectType']>) => {
      const state = getStateObject(sliceState);
      state.objectType = action.payload;
    },
    setChartPresetSettingsСompletionStatusMap: (
      state,
      action: PayloadAction<{ key: EPageWithChartType; isCompleted: boolean }>
    ) => {
      const { key, isCompleted } = action.payload;
      state.сhartPresetSettingsСompletionStatusMap[key] = isCompleted;
    },
    clearChartPresetSettingsСompletionStatusMap: (state) => {
      state.сhartPresetSettingsСompletionStatusMap = initialState.сhartPresetSettingsСompletionStatusMap;
      state.currentChartType = EChartType.dotDensity;
    },
    toggleIsTickLabelsVisible: (sliceState) => {
      const state = getStateObject(sliceState);
      state.isTickLabelsVisible = !state.isTickLabelsVisible;
    },
    setContourBandWidth: (sliceState, action: PayloadAction<TInitialChartSettingsState['contourBandWidth']>) => {
      const state = getStateObject(sliceState);
      state.contourBandWidth = action.payload;
    },
    setSpecificChartSettingsToAllDatasets: (state, action: PayloadAction<{ fullScreenChartDataId: string }>) => {
      const { fullScreenChartDataId } = action.payload;

      const specificSettings = state.specificDatasetOptionMap?.[fullScreenChartDataId];

      state.currentChartType = specificSettings.currentChartType ?? state.currentChartType;
      state.axesScaleTypes = specificSettings.axesScaleTypes ?? state.axesScaleTypes;
      state.currentColorScale = specificSettings.currentColorScale ?? state.currentColorScale;
      state.isObjectEntityEnabled = specificSettings.isObjectEntityEnabled ?? state.isObjectEntityEnabled;
      state.isCageLvlForced = specificSettings.isCageLvlForced ?? state.isCageLvlForced;

      // TODO: think about optimizing state rewriting
      state.customRangesMap = {
        ...state.customRangesMap,
        ...specificSettings.customRangesMap,
      };

      state.customRangesMapName = specificSettings.customRangesMapName ?? state.customRangesMapName;
      state.customPlotRange = specificSettings.customPlotRange ?? state.customPlotRange;
      state.objectType = specificSettings.objectType ?? state.objectType;
      state.isTickLabelsVisible = specificSettings.isTickLabelsVisible ?? state.isTickLabelsVisible;
      state.contourBandWidth = specificSettings.contourBandWidth ?? state.contourBandWidth;
    },
  },
  extraReducers(builder) {
    builder.addCase(experimentActions.setCurrentExperimentId, (state) => {
      state.currentChartType = initialState.currentChartType;
      state.axesScaleTypes = initialState.axesScaleTypes;
      state.cageLevelAxesOptionListByLanes = initialState.cageLevelAxesOptionListByLanes;
      state.objectLevelAxesOptionListByLanes = initialState.objectLevelAxesOptionListByLanes;
      state.isObjectEntityEnabled = initialState.isObjectEntityEnabled;
      state.isCageLvlForced = initialState.isCageLvlForced;
      state.customRangesMap = initialState.customRangesMap;
      state.customRangesMapName = initialState.customRangesMapName;
      state.customPlotRange = initialState.customPlotRange;
      state.plotRangesMap = initialState.plotRangesMap;
      state.plotRangesMapName = initialState.plotRangesMapName;
      state.lastPlotRangeName = initialState.lastPlotRangeName;
      state.plotRangesMap = initialState.plotRangesMap;
      state.plotRangesMapName = initialState.plotRangesMapName;
    });
    builder.addMatcher(cdnAPI.endpoints.fetchCageEntityList.matchFulfilled, (state, { payload, meta }) => {
      const lane = meta.arg.originalArgs;
      if (lane) {
        state.cageLevelAxesOptionListByLanes[lane.dataset.name] = getEntityLevelAxesOptions(payload[0], lane);
      }
    });
    builder.addMatcher(cdnAPI.endpoints.fetchObjectEntityList.matchFulfilled, (state, { payload, meta }) => {
      const lane = meta.arg.originalArgs;
      if (lane) {
        state.objectLevelAxesOptionListByLanes[lane.dataset.name] = getEntityLevelAxesOptions(payload[0]);
      }
    });
  },
});

export default chartSettingsSlice;
