import { v4 as uuidv4 } from 'uuid';

import { transformMap } from './helpers';
import { TReturnTransformedTypes } from './types';

export const transformAnnotationResponse = (annotation: TGeneralAnnotationFields): TReturnTransformedTypes =>
  transformMap[annotation.type]?.(annotation) ?? {
    ...annotation,
    uuid: uuidv4(),
  };

export const transformAnnotationsResponse = (annotations: TGeneralAnnotationFields[]): TReturnTransformedTypes[] =>
  annotations.map((annotation) => transformAnnotationResponse(annotation));
