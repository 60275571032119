import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CagingSettingsItem, CellFunction, CellType } from '@/graphql/API';
import icons from '@/components/common/icons';

export const CARD_HEADER_SECTIONS = ['Cells to cage', 'Cells to substract', 'Advanced settings'];

export const CAGE_SETTINGS_MAIN_SECTIONS = [
  {
    key: 'magnigication',
    sections: [
      {
        title: 'Magnification',
        key: 'magnification',
      },
    ],
  },
  {
    key: 'cellsToCage',
    sections: [
      {
        title: 'Cell name',
        key: 'cellsToCageCellName',
      },
      {
        title: 'function',
        key: 'cellsToCageFunction',
      },
      {
        title: 'Ex Em',
        key: 'cellsToCageExEm',
      },
    ],
  },
  {
    key: 'cellsToSubstract',
    sections: [
      {
        title: 'Cell name',
        key: 'cellsToSubstractCellName',
      },
      {
        title: 'function',
        key: 'cellsToSubstractFunction',
      },
      {
        title: 'Ex Em',
        key: 'cellsToSubstractExEm',
      },
    ],
  },
  {
    key: 'advanced',
    sections: [
      {
        title: 'Advanced',
        key: 'advanced',
      },
    ],
  },
];

export const RESTRICTED_ACCESS_SETTINGS = ['cageWallThickness', 'maxCageAreaFraction'];

export const DOMAIN_TO_VALIDATE_ACCESS = '@cellanome.com';

export const ADVANCED_SETTINGS: {
  title: string;
  key: keyof Omit<CagingSettingsItem, '__typename'>;
  withWiki?: boolean;
  icon?: ReactNode;
}[] = [
  {
    title: 'Cage Radius (um)',
    key: 'cageRadius',
    withWiki: true,
    icon: <icons.CageRadiusIcon />,
  },
  {
    title: 'Cage to cage distance',
    key: 'cageToCageDistance',
    withWiki: true,
    icon: <icons.CageToCageDistanceIcon />,
  },
  {
    title: 'Cell to cage wall min distance (um)',
    key: 'cellToCageWallMinDistance',
    withWiki: true,
    icon: <icons.CellToCageWallMinDistanceIcon />,
  },
  {
    title: 'Min Cell Size (um)',
    key: 'minCellSize',
    withWiki: true,
    icon: <icons.MinCellSizeIcon />,
  },
  {
    title: 'Max Cell Size (um)',
    key: 'maxCellSize',
    withWiki: true,
    icon: <icons.MaxCellSizeIcon />,
  },
  {
    title: 'Max number of Control Cages',
    key: 'maxNumberOfControlCages',
    icon: <icons.MaxNumberOfControlCagesIcon />,
  },
  {
    title: 'Cage wall to other objects min distance (um)',
    key: 'cageWallToOtherObjsMinDistance',
    withWiki: true,
    icon: <icons.CageWallToOtherObjectsMinDistanceIcon />,
  },
];

export const RESTRICTED_ADVANCED_SETTINGS: {
  title: string;
  key: keyof Omit<CagingSettingsItem, '__typename'>;
  withWiki?: boolean;
  icon?: ReactNode;
}[] = [
  {
    title: 'Cage wall thickness(um)',
    key: 'cageWallThickness',
    withWiki: true,
    icon: <icons.CageWallThicknessIcon />,
  },
  {
    title: 'Max Cage Area Fraction',
    key: 'maxCageAreaFraction',
  },
  {
    title: 'DMD exposure',
    key: 'dmdExposure',
  },
  {
    title: 'DMD Intensity (sec)',
    key: 'dmdIntensity',
  },
  {
    title: 'Auto-focus current (%)',
    key: 'autoFocusCurrent',
  },
  {
    title: 'Auto-focus exp. time (ms)',
    key: 'autoFocusExposureTime',
  },
  {
    title: 'Z offset',
    key: 'zOffset',
  },
];

export const DEFAULT_MAGNIFICATION = 4;

const uuid = uuidv4();

export const CAGING_SETTINGS_WAVES_DEFAULT_REAGENT = {
  label: 'Bright Field',
  value: uuid,
  customData: {
    type: 'Default',
    reagent: {
      __typename: 'Reagent',
      id: uuid,
      name: 'Bright Field',
      type: 'Reagent',
      annotations: null,
      detectionWavelength: 0,
      excitationWavelength: 0,
      marker: '',
      phenotype: null,
    },
  },
};

export const DEFAULT_CELL_TYPE: CellType = {
  __typename: 'CellType',
  name: '',
  function: CellFunction.EFFECTOR,
  otherFunction: null,
  preTreatment: null,
  preLabeling: null,
  cellIndex: null,
};

export const DEFAULT_GLOBAL_SETTINGS = {
  cageWallThickness: 7,
  cageWallToOtherObjsMinDistance: 15,
  maxNumberOfControlCages: 1,
  cageRadius: 45,
  cageToCageDistance: 15,
  cellToCageWallMinDistance: 15,
  minCellSize: 5,
  maxCellSize: 1,
  maxCageAreaFraction: 0.37,
  dmdExposure: 5,
  dmdIntensity: 91,
  autoFocusCurrent: 0,
  autoFocusExposureTime: 10,
  zOffset: 0,
};
