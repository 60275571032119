import { MouseEvent, FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames/bind';

import { getChannelUiColor } from '@/helpers/channels';
import { CSSProperty } from '@/helpers/interfaces';
import { addTooltip, formatDate, NDASH } from '@/helpers';

import { useParamsExperimentId } from '@/hooks';
import { useLinks } from '@/hooks/useLinks';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';
import { datasetsSelectors, datasetsActions } from '@/store/slices/datasets';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import CheckboxInput from '@/components/common/CheckboxInput';
import icons from '@/components/common/icons';
import Input from '@/components/common/Input';

import ChannelReagent from './ChannelReagent';
import ChannelAssay from './ChannelAssay';

import styles from './ChannelDetailsTable.module.scss';

const cn = classnames.bind(styles);

type TChannelDetailsRow = {
  channelDetails: TDatasetDetails;
  isMinimalView: boolean;
};

const ChannelDetailsRow: FC<TChannelDetailsRow> = ({ channelDetails, isMinimalView }) => {
  const appDispatch = useAppDispatch();

  const { generateChartLink } = useLinks();
  const experimentId = useParamsExperimentId();
  const { openNavigator } = useExperimentModalsContext();

  const scan = useSelector(experimentSelectors.selectScan(channelDetails.scanId));
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const isEditMode = useSelector(datasetsSelectors.selectEditMode);

  const [updateExperimentDatasetFriendlyName] = appAPI.useUpdateExperimentDatasetFriendlyNameMutation();

  const handleVoidClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleCheckboxChange = () => null;

  const handleNavigatorButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    openNavigator({ dataset: channelDetails.dataset });
  };

  const handleRowClick = () => {
    if (isPreprocessingView) {
      return;
    }
    appDispatch(datasetsActions.toggleChannelDetails(channelDetails.id));
  };

  const handleSaveValue = (currentValue: string) => {
    const {
      dataset: { scanId, laneId },
    } = channelDetails;

    updateExperimentDatasetFriendlyName({
      scanId,
      laneId,
      experimentId,
      friendlyName: currentValue.trim(),
    });
  };

  return (
    <tr aria-hidden onClick={handleRowClick} className={cn('detailed-row', { 'detailed-row_edit': isEditMode })}>
      {!isPreprocessingView && (
        <td className={cn('detailed-row__cell')} aria-label="checkbox">
          <CheckboxInput
            checked={channelDetails.checked}
            onChange={handleCheckboxChange}
            className={cn('detailed-row__checkbox')}
            theme="light"
          />
        </td>
      )}
      <td className={cn('detailed-row__cell', 'detailed-row__cell_name', 'detailed-row__cell_line-holder')}>
        {isEditMode && (
          <div onClick={(e) => e.stopPropagation()} role="presentation" className={cn('detailed-row__input-wrapper')}>
            <Input initialValue={channelDetails.dataset.friendlyName} handleSave={handleSaveValue} />
          </div>
        )}
        {!isEditMode && (
          <span
            {...addTooltip(channelDetails.friendlyName)}
            className={cn('detailed-row__data-text', 'channel-details-table__dataset-name')}
          >
            {channelDetails.friendlyName}
          </span>
        )}
      </td>
      <td
        className={cn('detailed-row__cell', 'detailed-row__cell_time', 'detailed-row__cell_center')}
        {...addTooltip(formatDate(scan?.scanDate, 'MMMM d, yyyy, p'))}
      >
        {scan?.timeNode}
      </td>
      <td className={cn('detailed-row__cell', 'detailed-row__cell_channel', 'detailed-row__cell_center')}>
        <div
          className={cn('detailed-row__channel-mark')}
          style={
            {
              '--bg-color': getChannelUiColor(channelDetails.channelName ?? ''),
            } as CSSProperty
          }
        >
          <span>{channelDetails.channelName}</span>
        </div>
      </td>
      {!isMinimalView && (
        <>
          <td aria-hidden className={cn('detailed-row__cell')} onClick={handleVoidClick}>
            {channelDetails.channelId && (
              <ChannelAssay
                isEditMode={!isPreprocessingView && isEditMode}
                experimentId={experimentId}
                datasetDetails={channelDetails}
              />
            )}
          </td>
          <td className={cn('detailed-row__cell')} {...addTooltip(channelDetails.marker?.marker)}>
            {channelDetails.marker?.marker ?? NDASH}
          </td>
          <td className={cn('detailed-row__cell', 'detailed-row__cell_center')}>
            {channelDetails.channelId && (
              <ChannelReagent isEditMode={isEditMode} experimentId={experimentId} datasetDetails={channelDetails} />
            )}
          </td>
          <td
            className={cn('detailed-row__cell', 'detailed-row__cell_control-items', 'detailed-row__cell_fit-content')}
          >
            <div className={cn('detailed-row__control-items')}>
              {!isPreprocessingView && (
                <NavLink to={generateChartLink(channelDetails)} onClick={handleVoidClick}>
                  <div {...addTooltip('View chart')} className={cn('control-item')}>
                    <icons.ChartViewIcon />
                  </div>
                </NavLink>
              )}
              <button
                {...addTooltip('Navigator')}
                className={cn('control-item')}
                onClick={handleNavigatorButtonClick}
                aria-label="toggle modal"
              >
                <icons.NavigatorIcon />
              </button>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default memo(ChannelDetailsRow);
