import { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './SwitchInput.module.scss';

type SwitchInputProps = {
  checked: boolean;
  offLabel: string;
  onLabel: string;
  onChange: () => void;
  type?: 'default' | 'small';
  disabled?: boolean;
};

const cn = classnames.bind(styles);
const SwitchInput: FC<SwitchInputProps> = ({
  checked,
  offLabel,
  onLabel,
  onChange,
  type = 'default',
  disabled = false,
}) => (
  <label className={cn('switch-input')} aria-label={`switch mode between "${onLabel}" and "${offLabel}"`}>
    <input
      type="checkbox"
      disabled={disabled}
      className={cn('switch-input__input')}
      checked={checked}
      onChange={onChange}
    />
    <div
      data-on={onLabel}
      data-off={offLabel}
      className={cn(
        'switch-input__inner',
        checked ? 'switch-input__inner_checked' : '',
        `switch-input__inner_${type}`,
        {
          'switch-input__inner_disabled': disabled,
        }
      )}
    />
  </label>
);

export default SwitchInput;
